import { useDrag, useDrop } from "react-dnd";
const PdfCard = ({
  pdf,
  index,
  onDelete,
  onReorder,
  hoveredIndex,
  setHoveredIndex,
  pdfFileNames,
  formatFileSize,
  isMobile,
}) => {
  const [, ref] = useDrag({
    type: "pdf",
    item: { index },
  });

  console.log("useDrag", ref);
  const [, drop] = useDrop({
    accept: "pdf",
    hover: (item) => {
      if (item.index !== index) {
        onReorder(item.index, index);
        item.index = index;
      }
    },
  });
  console.log("useDrop", drop);
  return (
    <div
      ref={(node) => ref(drop(node))}
      key={pdf.name}
      className={`mt-3 ${!isMobile ? "pdf-card" : "pdf-card"}`}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {isMobile ? (
        <>
          <canvas
            className={`pdf-canvas-${index}`}
            width="160"
            height="200"
            style={{
              boxShadow:
                "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
              marginTop: "0.5rem",
            }}
          ></canvas>
          {hoveredIndex === index && (
            <button
              className="canvas-delete-btn mt-2"
              onClick={() => onDelete(index)}
            >
              <i
                class="fa-solid fa-xmark"
                style={{
                  margin: "0px",
                  padding: "0.33rem 0 0 0",
                  width: "25px",
                  height: "25px",
                }}
              ></i>
            </button>
          )}
          <div class="name-box">
            <p class="name">
              {pdfFileNames[index].name} <br />
              {formatFileSize(pdfFileNames[index].size)}
            </p>
          </div>
        </>
      ) : (
        <>
          <canvas
            className={`pdf-canvas-${index}`}
            width="160"
            height="200"
            style={{
              boxShadow:
                "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
              marginTop: "0.5rem",
            }}
          ></canvas>
          {hoveredIndex === index && (
            <button
              className="canvas-delete-btn mt-2"
              onClick={() => onDelete(index)}
            >
              <i
                class="fa-solid fa-xmark"
                style={{
                  margin: "0px",
                  padding: "0.33rem 0 0 0",
                  width: "25px",
                  height: "25px",
                }}
              ></i>
            </button>
          )}
          <div class="name-box">
            <p class="name">
              {pdfFileNames[index].name} <br />
              {formatFileSize(pdfFileNames[index].size)}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PdfCard;
