import React, { useState, useRef, useEffect } from "react";
import { saveAs } from "file-saver";
import pdfIcon1 from "../assets/pdfIcon (1).png";
import JSZip from "jszip";
import Footer from "./Footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const MAX_COUNT = 20;
const PdfToImg = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pdf, setPdf] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfFileNames, setPdfFileNames] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0); // New state for total files
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [pageRendering, setPageRendering] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dropzoneBackground, setDropzoneBackground] = useState(false);

  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [canvases, setCanvases] = useState([]); // Array of canvas elements

  const [pdfImages, setPdfImages] = useState([]);

  const fileInputRef = useRef(null);

  const handleFileInputChange = async (e) => {
    e.preventDefault();

    const files = e.target.files;

    // Check file count
    if (files.length + pdf.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }

    // Check duplicate files
    const duplicateFiles = Array.from(files).filter((file) =>
      pdf.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }

    const newFiles = Array.from(files);

    setPdf((prevFiles) => [...prevFiles, ...newFiles]);
    setTotalFiles((prevTotalFiles) => prevTotalFiles + newFiles.length);

    const newPdfFileNames = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));
    // Concatenate new file names with existing ones
    setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);

    try {
      setPdfRendering(true);
      const pdfDocs = await Promise.all(
        Array.from(files).map(async (file) => {
          const uri = URL.createObjectURL(file);
          return await window.pdfjsLib.getDocument({ url: uri });
        })
      );
      const newCanvases = Array.from(pdfDocs).map(() =>
        document.createElement("canvas")
      );
      // Concatenate new canvases with existing ones
      setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

      // Concatenate new PDF images with existing ones
      setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);

      setPdfRendering(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDropzoneBackground(false);
    const droppedFiles = e.dataTransfer.files;

    // Check file count
    if (droppedFiles.length + pdf.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }

    // Check duplicate files
    const duplicateFiles = Array.from(droppedFiles).filter((file) =>
      pdf.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }

    if (droppedFiles.length > 0) {
      // Concatenate new files with existing ones
      const newFiles = Array.from(droppedFiles);
      setPdf((prevFiles) => [...prevFiles, ...newFiles]);
      setTotalFiles((prevTotalFiles) => prevTotalFiles + newFiles.length);

      // Update the pdfFileNames array with the names
      const newPdfFileNames = Array.from(droppedFiles).map((file) => ({
        name: file.name,
        size: file.size,
      }));
      setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);

      try {
        setPdfRendering(true);
        const pdfDocs = await Promise.all(
          newFiles.map(async (file) => {
            const uri = URL.createObjectURL(file);
            return await window.pdfjsLib.getDocument({ url: uri });
          })
        );
        const newCanvases = Array.from(pdfDocs).map(() =>
          document.createElement("canvas")
        );
        // Concatenate new canvases with existing ones
        setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

        // Concatenate new PDF images with existing ones
        setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);

        setPdfRendering(false);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  async function renderPage(index) {
    console.log("index:", index);
    setPageRendering(true);
    const selectedPdf = pdfImages[index];

    var page = await selectedPdf?.getPage(currentPage);

    var viewport = page?.getViewport({ scale: 1 });
    const scale = Math.min(200 / viewport.width, 200 / viewport.height);

    var render_context = {
      canvasContext: document
        .querySelector(`.pdf-canvas-${index}`)
        ?.getContext("2d"),
      viewport: page.getViewport({ scale }),
    };
    setWidth(viewport.width * scale);
    setHeight(viewport.height * scale);
    await page.render(render_context);
    setPageRendering(false);
  }
  // render image in img tag use effect
  useEffect(() => {
    for (let i = 0; i < pdfImages.length; i++) {
      renderPage(i);
    }
    // eslint-disable-next-line
  }, [pdfImages, currentPage]);

  const handleDeletePdf = (index) => {
    // Copy the current state arrays
    const newSelectedFile = [...pdf];
    const newPdfImages = [...pdfImages];
    const newPdfFileNames = [...pdfFileNames];

    newSelectedFile.splice(index, 1);
    newPdfImages.splice(index, 1);
    newPdfFileNames.splice(index, 1);

    setPdf(newSelectedFile);
    setPdfImages(newPdfImages);
    setPdfFileNames(newPdfFileNames);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDropzoneBackground(true);
  };

  const convertPdfToImages = async () => {
    if (pdf.length > 0) {
      setIsLoading(true);

      const zip = new JSZip();

      for (let pdfIndex = 0; pdfIndex < pdf.length; pdfIndex++) {
        const initialPdf = pdf[pdfIndex];

        const pdfData = await initialPdf.arrayBuffer();
        const pdfDoc = await window.pdfjsLib.getDocument(pdfData).promise;
        console.log("pdfDoc", pdfDoc);

        for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
          const page = await pdfDoc.getPage(pageNum);
          const viewport = page.getViewport({ scale: 2 }); // Adjust scale as needed(quality can be mentain)

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = viewport.width;
          canvas.height = viewport.height;

          const renderTask = page.render({ canvasContext: context, viewport });
          await renderTask.promise;

          const imageDataUrl = canvas.toDataURL("image/jpeg");
          zip.file(
            `pdf_${pdfIndex}_page_${pageNum}.jpg`,
            imageDataUrl.split(",")[1],
            { base64: true }
          );
        }
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "Images.zip");
      setIsLoading(false);
    } else {
      alert("Please upload PDF files first.");
    }
  };
  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };

  return (
    <>
      <div className="main-pdf-body pt-2 pb-4 mt-5 mb-5">
        <div
          className="container"
          style={{
            border: "2px dotted #00bb87",
            padding: "0px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            className="border text-center d-flex align-items-center justify-content-center drop-area"
            style={{
              height: "200px",
              cursor: "pointer",
              background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()} // Add this line
          >
            <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
              Select or drop your files here <br />
              <i
                className="fa-solid fa-cloud-arrow-up"
                style={{ fontSize: "5rem", color: "#54bc8b" }}
              ></i>
            </p>
          </div>
          <input
            type="file"
            id="fileInput"
            accept="application/pdf"
            multiple
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
        <div
          className={`container mt-3 mb-5 px-0 ${
            !isMobile ? "pdf-container" : ""
          }`}
        >
          {pdfImages.map((canvas, index) => (
            <div
              key={index}
              className={`mt-3 ${
                !isMobile ? "image-to-pdf-card" : "mobile-card"
              }`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              {isMobile ? (
                <>
                  {hoveredIndex === index && (
                    <button
                      className="image-delete-btn mt-2"
                      onClick={() => handleDeletePdf(index)}
                      style={{ right: "-8px" }}
                    >
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          margin: "0px",
                          padding: "0.33rem 0 0 0",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </button>
                  )}
                  <div
                    className="row px-0 mx-0"
                    style={{
                      boxShadow: "0 2px 5px 2px rgba(0,0,0,0.09)",
                      borderRadius: "7px",
                    }}
                  >
                    <div className="col-12 py-2 d-flex justify-content-between">
                      <img
                        src={pdfIcon1}
                        alt="pdf-img"
                        className="pdf-image p-0"
                      />

                      <p class="name fw-bold">
                        {pdfFileNames[index].name}
                        <br />
                        {formatFileSize(pdfFileNames[index].size)}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <canvas
                    className={`pdf-canvas-${index}`}
                    width="163"
                    height="215"
                    style={{
                      boxShadow:
                        "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                    }}
                  ></canvas>
                  {hoveredIndex === index && (
                    <button
                      className="image-delete-btn mt-2"
                      onClick={() => handleDeletePdf(index)}
                    >
                      <i
                        class="fa-solid fa-xmark"
                        style={{
                          margin: "0px",
                          padding: "0.33rem 0 0 0",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </button>
                  )}
                  <div class="image-box">
                    <p class="name fw-bold">
                      {pdfFileNames[index].name}
                      <br />
                      {formatFileSize(pdfFileNames[index].size)}
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer
        pdf={pdf}
        convertPdfToImages={convertPdfToImages}
        buttonLoading={isLoading}
      />
    </>
  );
};

export default PdfToImg;
