import React from "react";
import "../App.css";

const Footer = ({
  onFlattenPdfClick,
  isCompressPdfRoute,
  onDownload,
  onUpload,
  isDisabled,
  pdfData,
  compressedPdfSize,
  onConvertToPdf,
  images,
  pdf,
  isLoading,
  buttonLoading,
  convertPdfToImages,
  onOpenResolutionModal,
  refersh,
  trackAllPdf,
  handleCompressAll,
  handleDownloadAll,
  totalCompressedSize,
  isRefersh,
  mergeSelectedFile,
  mergePDF,
  downloadMergedPDF,
  mergeIsDisabled,
  allclear,
  splitUserPdf,
  openUserModal,
}) => {
  const convertBytesToKB = (bytes) => {
    return (bytes / 1000).toFixed(2); // Keep it to two decimal places
  };

  const handleRefershClick = () => {
    refersh();
  };
  const handleDownloadAllClick = () => {
    handleDownloadAll();
  };
  const handleCompressAllClick = () => {
    handleCompressAll();
  };
  return (
    <div className="footer" style={{ overflowX: "hidden" }}>
      <div className="footer-content container px-0">
        {isCompressPdfRoute ? (
          <>
            {isDisabled ? (
              pdfData ? (
                <>
                  <button
                    className="fw-bold pdf-btn mobile-margin"
                    onClick={() => onDownload()}
                  >
                    Download Compressed PDF (
                    {compressedPdfSize
                      ? `${convertBytesToKB(compressedPdfSize)} KB`
                      : "N/A"}
                    )
                  </button>
                </>
              ) : (
                <>
                  {isRefersh ? (
                    <button
                      className="pdf-btn mobile-margin me-sm-0"
                      onClick={handleRefershClick}
                    >
                      Refresh
                    </button>
                  ) : (
                    <button
                      className="fw-bold pdf-btn mobile-margin me-sm-0"
                      onClick={() => onUpload()}
                    >
                      Compress PDF
                    </button>
                  )}
                </>
              )
            ) : (
              <>
                {trackAllPdf ? (
                  <button
                    className="pdf-btn mobile-margin me-sm-0"
                    onClick={handleDownloadAllClick}
                  >
                    Download All
                  </button>
                ) : allclear ? (
                  <button
                    className="pdf-btn mobile-margin me-sm-0"
                    onClick={handleRefershClick}
                  >
                    Refresh{" "}
                  </button>
                ) : (
                  <button
                    className="pdf-btn mobile-margin me-sm-0"
                    onClick={handleCompressAllClick}
                    disabled={trackAllPdf}
                  >
                    {" "}
                    Compress All
                  </button>
                )}
              </>
            )}
          </>
        ) : isDisabled ? (
          <>
            <button
              className="fw-bold pdf-btn me-sm-0 mobile-margin"
              onClick={onFlattenPdfClick}
            >
              Flatten PDF
            </button>
          </>
        ) : null}
        {/* New button for converting images to PDF */}
        {images?.length > 0 ? (
          <button
            className={`fw-bold pdf-btn mobile-margin me-sm-0 ${
              isLoading ? "loading" : ""
            }`}
            onClick={onConvertToPdf}
            disabled={isLoading}
          >
            {isLoading ? (
              <div
                className="spinner-border spinner-border-sm p-0"
                role="status"
              >
                <span className="sr-only text-light">Loading...</span>
              </div>
            ) : (
              "Convert To PDF"
            )}
          </button>
        ) : null}
        {pdf?.length > 0 ? (
          <button
            className={`fw-bold  mobile-margin me-sm-0 pdf-btn ${
              isLoading ? "loading" : ""
            }`}
            onClick={convertPdfToImages}
            disabled={isLoading}
          >
            {buttonLoading ? (
              <div
                className="spinner-border spinner-border-sm text-light p-0"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Convert To Images"
            )}
          </button>
        ) : null}
        {mergeSelectedFile ? (
          mergeIsDisabled ? (
            <button
              className="pdf-btn mobile-margin me-sm-0"
              onClick={() => downloadMergedPDF()}
            >
              Download Merge PDF
            </button>
          ) : (
            <button
              className="pdf-btn mobile-margin me-sm-0"
              onClick={() => mergePDF()}
            >
              Merge PDF
            </button>
          )
        ) : null}
        {splitUserPdf ? (
          <button
            className="pdf-btn mobile-margin me-sm-0"
            onClick={() => openUserModal()}
          >
            Split PDF
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
