import React, { useRef, useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import JSZip from "jszip";

import Footer from "./Footer";
import SplitPDFModal from "./SplitPDFModal";

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  margin: "0 auto",
};
const thumb = {
  display: "flex",
  flexDirection: "column",
  borderRadius: 2,
  marginBottom: 10,
  marginRight: 14.2,
  margin: "0 auto",
  width: 180,
  height: 230,
  boxSizing: "border-box",
  boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
};
const SplitPDF = () => {
  const [dropzoneBackground, setDropzoneBackground] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedPages, setSelectedPages] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [customPagesInput, setCustomPagesInput] = useState("");
  const [index1, setIndex1] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [image, setImage] = React.useState("");
  const [pageRendering, setPageRendering] = React.useState("");
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [customSelectedPages, setCustomSelectedPages] = useState([]);

  const [pdf, setPdf] = useState();

  const handleFileInputChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const pdfBytes = await file?.arrayBuffer();
    const pdfDoc = await PDFDocument?.load(pdfBytes);

    setTotalPages(pdfDoc.getPageCount());
    setSelectedFile(file);
    try {
      setPdfRendering(true);
      const uri = URL.createObjectURL(file);
      var _PDF_DOC = await window?.pdfjsLib?.getDocument({ url: uri });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);

  const handleDrop = async (e) => {
    e.preventDefault();
    setDropzoneBackground(false);

    const droppedFile = e.dataTransfer.files[0];
    const pdfBytes = await droppedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);

    setTotalPages(pdfDoc.getPageCount());
    setSelectedFile(droppedFile);
    try {
      setPdfRendering(true);
      const uri = URL.createObjectURL(droppedFile);
      var _PDF_DOC = await window?.pdfjsLib?.getDocument({ url: uri });
      setPdf(_PDF_DOC);
      setPdfRendering(false);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDropzoneBackground(true);
  };
  async function renderPage() {
    setPageRendering(true);

    var page = await pdf?.getPage(currentPage);

    var viewport = page?.getViewport({ scale: 1 });
    const scale = Math.min(200 / viewport.width, 215 / viewport.height);

    var render_context = {
      canvasContext: document?.querySelector("#pdf-canvas")?.getContext("2d"),
      viewport: page.getViewport({ scale }),
    };

    await page.render(render_context);

    var canvas = document.getElementById("pdf-canvas");
    var img = canvas.toDataURL("image/png");
    setImage(img);
    setPageRendering(false);
  }

  useEffect(() => {
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf, currentPage]);

  const splitPDF = async () => {
    try {
      if (!selectedFile) {
        return;
      }

      const pdfBytes = await selectedFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const zip = new JSZip();

      let currentPage = 1;

      // Determine the number of cards based on the selected range
      const cardsToRender = Math.ceil(totalPages / selectedPages);

      for (let cardIndex = 0; cardIndex < cardsToRender; cardIndex++) {
        const startPage = cardIndex * selectedPages;
        const endPage = Math.min((cardIndex + 1) * selectedPages, totalPages);

        const newDoc = await PDFDocument.create();

        // Copy pages for the current card
        for (let pageNum = startPage; pageNum < endPage; pageNum++) {
          const [copiedPage] = await newDoc.copyPages(pdfDoc, [pageNum]);
          newDoc.addPage(copiedPage);
        }

        // Save the new PDF document to a file in the ZIP archive
        const newPdfBytes = await newDoc.save();
        zip.file(`a2z-SplitPdf${startPage + 1}-${endPage}.pdf`, newPdfBytes);
        currentPage = endPage + 1;
      }

      // Generate the ZIP file containing the split PDFs
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipUrl = URL.createObjectURL(zipBlob);

      // Download the ZIP file
      const a = document.createElement("a");
      a.href = zipUrl;
      a.download = `a2z-SplitPdf_${selectedPages}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error splitting PDF:", error);
    }
  };

  const generateSinglePDF = async () => {
    try {
      if (!selectedFile || !customPagesInput) {
        console.log("Inside if condition");
        return;
      }
      const pdfBytes = await selectedFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const zip = new JSZip();
      const customPages = customPagesInput
        .split(",")
        .map((page) => page.trim())
        .filter((page) => page !== "")
        .map((page) => Number(page))
        .filter((page) => page >= 1 && page <= totalPages);

      setIndex1(customPages.length);

      for (let i = 0; i < customPages.length; i++) {
        const customPage = customPages[i];
        const newDoc = await PDFDocument.create();

        const [copiedPage] = await newDoc.copyPages(pdfDoc, [customPage - 1]);
        newDoc.addPage(copiedPage);

        const newPdfBytes = await newDoc.save();
        zip.file(`a2z-SplitPdf${customPage}.pdf`, newPdfBytes);
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipUrl = URL.createObjectURL(zipBlob);
      const a = document.createElement("a");
      a.href = zipUrl;
      a.download = `a2z-SplitPdf_${customPagesInput}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating single PDF:", error);
    }
  };

  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };
  const openModal = () => {
    setShowModal(true);
    setIsOpen(true);
  };

  async function generateUrlFromCanvas() {
    setPageRendering(true);
    const imagesList = [];
    for (let i = 1; i <= pdf?.numPages; i++) {
      const canvas = document.createElement("canvas");
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const context = canvas.getContext("2d");
      await page.render({ canvasContext: context, viewport });
      const img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImageUrls(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    selectedFile && generateUrlFromCanvas();
  }, [pdf]);

  const mergePDF = async () => {
    try {
      const mergedDoc = await PDFDocument.create();
      const fileBytes = await selectedFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(fileBytes);
      const customPages = customPagesInput
        .split(",")
        .map((page) => page.trim())
        .filter((page) => page !== "")
        .map((page) => Number(page))
        .filter((page) => page >= 1 && page <= pdfDoc.getPageCount());
      const copiedPages = await mergedDoc.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );

      const selectedPageIndices = customSelectedPages.map((page) => page - 1);
      const startIndex = selectedPageIndices[0];
      const lastIndex = selectedPageIndices[selectedPageIndices.length - 1];

      if (customPages.length > 0) {
        for (let i = 0; i < customPages.length; i++) {
          const pageIndex = customPages[i];
          for (let j = 0; j < copiedPages.length; j++) {
            const pageNumber = j + 1; // Add 1 because page numbers start from 1
            if (pageIndex === pageNumber) {
              // Compare with the page number, not the index
              mergedDoc.addPage(copiedPages[j]);
              break;
            }
          }
        }
      } else {
        for (let i = startIndex; i <= lastIndex; i++) {
          // const pageIndex = selectedPageIndices[i];
          mergedDoc.addPage(copiedPages[i]);
        }
      }

      const mergedPdfBytes = await mergedDoc.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(mergedPdfBlob);
      downloadLink.download = "merged_pdf.pdf";
      downloadLink.click();
    } catch (error) {
      alert(error.message);
    }
  };

  const deleteImage = (val) => {
    setSelectedFile(null);
    setImageUrls([]);
    setPdf();
    // Clear the file input value
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <>
      <div className="main-pdf-body pt-2 pb-4 mt-5">
        <div
          className="container"
          style={{
            border: "2px dotted #00bb87",
            padding: "0px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            className="border text-center d-flex align-items-center justify-content-center drop-area"
            style={{
              height: "200px",
              cursor: "pointer",
              background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()}
          >
            <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
              Select or drop your file here <br />
              <i
                className="fa-solid fa-cloud-arrow-up"
                style={{ fontSize: "5rem", color: "#54bc8b" }}
              ></i>
            </p>
          </div>
          <input
            type="file"
            id="fileInput"
            accept="application/pdf"
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <div className="selected-file container px-0 mb-5">
        {selectedFile && (
          <>
            <div className="pb-3">
              <div
                className="pdf-card"
                style={{ margin: "0 auto" }}
                onMouseEnter={() => {
                  setHoveredIndex(0);
                }}
                onMouseLeave={() => {
                  setHoveredIndex(null);
                }}
              >
                <canvas
                  className="text-center"
                  id="pdf-canvas"
                  width="160"
                  height="215"
                  style={{
                    boxShadow:
                      "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                    marginTop: "0.5rem",
                  }}
                ></canvas>
                {hoveredIndex === 0 && (
                  <button
                    className="image-delete-btn mt-3"
                    onClick={() => deleteImage(0)}
                  >
                    <i
                      className="fa-solid fa-xmark"
                      style={{
                        margin: "0px",
                        padding: "0.33rem 0 0 0",
                        width: "25px",
                        height: "25px",
                      }}
                    ></i>
                  </button>
                )}
                <div class="image-box" style={{ margin: "10px auto" }}>
                  <p class="name fw-bold">
                    {selectedFile.name}
                    <br />
                    {formatFileSize(selectedFile.size)}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {showModal && (
          <SplitPDFModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            totalPages={totalPages}
            selectedFile={selectedFile}
            thumb={thumb}
            thumbInner={thumbInner}
            selectedPages={selectedPages}
            setSelectedPages={setSelectedPages}
            splitPDF={splitPDF}
            setCustomPagesInput={setCustomPagesInput}
            customPagesInput={customPagesInput}
            generateSinglePDF={generateSinglePDF}
            setShowModal={setShowModal}
            imageUrls={imageUrls}
            mergePDF={mergePDF}
            customSelectedPages={customSelectedPages}
            setCustomSelectedPages={setCustomSelectedPages}
          />
        )}
      </div>
      <Footer
        splitUserPdf={selectedFile}
        splitPDF={splitPDF}
        openUserModal={openModal}
      />
    </>
  );
};

export default SplitPDF;
