import React from "react";
import "../App.css";
const BodyHeading = ({ headingData }) => {
  return (
    <>
      <div className="text-center px-5 container" style={{ marginTop: "8rem" }}>
        <h1 className="fw-bold heading1">{headingData.heading}</h1>
        <h4 className="heading2">{headingData.subHeading}</h4>
      </div>
    </>
  );
};

export default BodyHeading;
