import React, { useState, useRef, useEffect } from "react";
import jsPDF from "jspdf";
import pdfIcon1 from "../assets/pdfIcon (1).png";
import "../App.css";
import Footer from "./Footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageModal from "./ImageModal";
const ImgToPdf = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dropzoneBackground, setDropzoneBackground] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [marginVal, setMarginVal] = useState("no-margin");
  const [selectedButton, setSelectedButton] = useState("portrait");

  const handleFileInputChange = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const newImages = Array.from(selectedFiles).map((file) => {
        return { id: Date.now(), file, name: file.name, size: file.size };
      });
      setImages([...images, ...newImages]);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    setDropzoneBackground(false);
    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles) {
      const newImages = Array.from(droppedFiles).map((file) => {
        return { id: Date.now(), file, name: file.name, size: file.size };
      });
      setImages([...images, ...newImages]);
    }
  };
  const handleDragOver = (e) => {
    setDropzoneBackground(true);
    e.preventDefault();
  };
  const deleteImage = (index) => {
    if (index !== null && index >= 0 && index < images.length) {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
      // Clear the file input value
      const fileInput = document.getElementById("fileInput");
      if (fileInput) {
        fileInput.value = "";
      }
    }
  };
  const convertImagesToPdf = async () => {
    if (images.length > 0) {
      setIsLoading(true);

      const doc = new jsPDF(
        selectedButton === "landscape" ? "l" : "p",
        "mm",
        "a4"
      );
      const marginSize =
        marginVal === "big" ? 16 : marginVal === "small" ? 8 : 0; // Determine margin size
      for (let index = 0; index < images.length; index++) {
        const image = images[index];
        const dataUrl = await readFileAsDataUrl(image.file);
        const img = new Image();
        // Set up the onload event to calculate dimensions after the image has loaded
        img.onload = function () {
          const aspectRatio = img.width / img.height;

          const maxWidth = selectedButton === "landscape" ? 297 : 210; // Adjust maxWidth for landscape
          const maxHeight = selectedButton === "landscape" ? 210 : 297; // Adjust maxHeight for landscape
          let width = img.width; //original width of the image
          let height = img.height; // original height of the image
          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }
          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }

          // Calculate vertical offset to center the image(make space)
          const verticalOffset =
            (doc.internal.pageSize.getHeight() - height) / 2;

          let horizontalOffset;
          if (selectedButton === "landscape") {
            horizontalOffset = marginSize;
          } else {
            horizontalOffset = marginSize;
          }
          // Add the image to the PDF using the calculated dimensions and vertical offset
          if (index > 0) {
            doc.addPage();
          }
          // add image to the pdf page
          doc.addImage(
            img,
            "JPEG",
            horizontalOffset,
            verticalOffset,
            width - 2 * marginSize,
            height
          );
          // If this is the last image, save the PDF
          if (index === images.length - 1) {
            doc.save("a2z-utilsPdf.pdf");
            setIsLoading(false);
            setLoader(false);
          }
        };
        img.src = dataUrl;
      }
    } else {
      alert("Please upload at least one image first.");
    }
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };
  const handleOpenModal = () => {
    setShowModal(true);
    console.log("Check modal value:", showModal);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal]);
  return (
    <>
      <div
        className="main-pdf-body pt-2 pb-4 px-sm-0 "
        style={{ overflow: "hidden" }}
      >
        <div
          className="container"
          style={{
            border: "2px dotted #00bb87",
            padding: "0px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            className="border text-center d-flex align-items-center justify-content-center drop-area"
            style={{
              height: "200px",
              cursor: "pointer",
              background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()}
          >
            <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
              Select or drop your images here <br />
              <i
                className="fa-solid fa-cloud-arrow-up"
                style={{ fontSize: "5rem", color: "#54bc8b" }}
              ></i>
            </p>
          </div>
          <input
            type="file"
            id="fileInput"
            accept=".png, .jpg, .jpeg"
            multiple
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
        {loader && (
          <div className="text-center mt-5">
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div
          className={`container mt-3  mb-5 ${!isMobile ? "pdf-container" : ""}`}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={`mt-3 ${
                !isMobile ? "image-to-pdf-card" : "mobile-card"
              }`}
              onMouseEnter={() => {
                setHoveredIndex(index);
              }}
              onMouseLeave={() => {
                setHoveredIndex(null);
              }}
            >
              {isMobile ? (
                <>
                  {hoveredIndex === index && (
                    <button
                      className="image-delete-btn mt-2"
                      onClick={() => deleteImage(index)}
                      style={{ right: "-18px" }}
                    >
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          margin: "0px",
                          padding: "0.33rem 0 0 0",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </button>
                  )}
                  <div
                    className="row"
                    style={{
                      boxShadow: "0 2px 5px 2px rgba(0,0,0,0.09)",
                      borderRadius: "7px",
                    }}
                  >
                    <div className="col-12 py-2 d-flex justify-content-between">
                      <img
                        src={pdfIcon1}
                        alt="pdf-img"
                        className="pdf-image p-0"
                      />
                      <p className="name ">
                        {image.name}
                        <br />
                        {formatFileSize(image.size)}
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={URL.createObjectURL(image.file)}
                    className="card-img-top mb-2"
                    alt="Uploaded"
                    style={{
                      width: "160px",
                      height: "215px",
                      objectFit: "cover",
                      boxShadow:
                        "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                    }}
                  />
                  {hoveredIndex === index && (
                    <button
                      className="image-delete-btn mt-2"
                      onClick={() => deleteImage(index)}
                    >
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          margin: "0px",
                          padding: "0.33rem 0 0 0",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </button>
                  )}
                  <div class="image-box">
                    <p class="name fw-bold">
                      {image.name}
                      <br />
                      {formatFileSize(image.size)}
                    </p>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer
        isDisabled={isDisabled}
        onConvertToPdf={handleOpenModal}
        images={images}
        isLoading={isLoading}
      />
      {showModal && (
        <ImageModal
          showModal={showModal}
          onClose={handleCloseModal}
          convertImagesToPdf={convertImagesToPdf}
          images={images}
          marginVal={marginVal}
          setMarginVal={setMarginVal}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
        />
      )}
    </>
  );
};

export default ImgToPdf;
