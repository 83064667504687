import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import pdfIcon1 from "../assets/pdfIcon (1).png";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Footer from "./Footer";
import CustomizedSlider from "./CustomizedSlider";
import "../App.css";
const MAX_COUNT = 20;
const Pdf = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fileInputRef = useRef(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [showResolutionModal, setShowResolutionModal] = useState(false);
  const [selectedResolution, setSelectedResolution] = useState(75);
  const [flattenedPdfSize, setFlattenedPdfSize] = useState(null);
  const [fSize, setFSize] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0); // New state for total files
  const [pdfImages, setPdfImages] = useState([]);
  const [canvases, setCanvases] = useState([]); // Array of canvas elements
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [dropzoneBackground, setDropzoneBackground] = useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [currentPageImg, setCurrentPageImg] = React.useState(1);
  const [pageRendering, setPageRendering] = React.useState("");
  const [pdfFileNames, setPdfFileNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [generatedPdf, setGeneratedPdf] = useState(null);
  const [flattenedPdfs, setFlattenedPdfs] = useState([]);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState("");
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [ready, setReady] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [processingMessage, setProcessingMessage] = useState();
  const [allFlattenDisabled, setAllFlattenDisabled] = useState(true);
  const [uploadedPdfCount, setUploadedPdfCount] = useState(0);
  const [handleModal, setHandleModal] = useState(false);

  const handleFileInputChange = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);

    const files = e.target.files;

    // Check file count
    if (files.length + selectedFile.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }

    // Check duplicate files
    const duplicateFiles = Array.from(files).filter((file) =>
      selectedFile.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }

    const newFiles = Array.from(files);

    setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);
    setTotalFiles((prevTotalFiles) => prevTotalFiles + newFiles.length);

    const newPdfFileNames = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size,
    }));
    // Concatenate new file names with existing ones
    setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);

    try {
      setPdfRendering(true);
      const pdfDocs = await Promise.all(
        Array.from(files).map(async (file) => {
          const uri = URL.createObjectURL(file);
          return await window.pdfjsLib.getDocument({ url: uri });
        })
      );
      const newCanvases = Array.from(pdfDocs).map(() =>
        document.createElement("canvas")
      );
      // Concatenate new canvases with existing ones
      setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

      // Concatenate new PDF images with existing ones
      setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);
      setUploadedPdfCount((prevCount) => prevCount + 1);
      setPdfRendering(false);
    } catch (error) {
      alert(error.message);
    }
  };
  const handleDrop = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    setDropzoneBackground(false);
    const droppedFiles = e.dataTransfer.files;

    // Check file count
    if (droppedFiles.length + selectedFile.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }
    // Check duplicate files
    const duplicateFiles = Array.from(droppedFiles).filter((file) =>
      selectedFile.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }
    if (droppedFiles.length > 0) {
      // Concatenate new files with existing ones
      const newFiles = Array.from(droppedFiles);
      setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);
      setTotalFiles((prevTotalFiles) => prevTotalFiles + newFiles.length);

      // Update the pdfFileNames array with the names
      const newPdfFileNames = Array.from(droppedFiles).map((file) => ({
        name: file.name,
        size: file.size,
      }));
      setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);
      try {
        setPdfRendering(true);
        const pdfDocs = await Promise.all(
          newFiles.map(async (file) => {
            const uri = URL.createObjectURL(file);
            return await window.pdfjsLib.getDocument({ url: uri });
          })
        );
        const newCanvases = Array.from(pdfDocs).map(() =>
          document.createElement("canvas")
        );
        // Concatenate new canvases with existing ones
        setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

        // Concatenate new PDF images with existing ones
        setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);

        setPdfRendering(false);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  // render page function
  async function renderPage(index) {
    console.log("index:", index);
    setPageRendering(true);
    const selectedPdf = pdfImages[index];

    var page = await selectedPdf?.getPage(currentPageImg);

    var viewport = page?.getViewport({ scale: 1 });
    const scale = Math.min(200 / viewport.width, 215 / viewport.height);
    var render_context = {
      canvasContext: document
        .querySelector(`.pdf-canvas-${index}`)
        ?.getContext("2d"),
      viewport: page.getViewport({ scale }),
    };
    setWidth(viewport.width * scale);
    setHeight(viewport.height * scale);
    console.log("width:", viewport.width * scale);
    console.log("height:", viewport.height * scale);

    await page.render(render_context);
    setPageRendering(false);
  }
  // render image in img tag use effect
  useEffect(() => {
    for (let i = 0; i < pdfImages.length; i++) {
      renderPage(i);
    }
    // eslint-disable-next-line
  }, [pdfImages, currentPage]);

  // handle download all flatten pdf
  const handleDownload = () => {
    if (generatedPdf) {
      // Convert the PDF to a Blob
      const pdfBlob = generatedPdf.output("blob");
      // Use FileSaver.js to trigger the download
      saveAs(pdfBlob, "FlattenedPDF.pdf");
    }
    setShowModal(false);
    setCurrentPage(1);
  };
  const handleBackToEditing = () => {
    if (selectedFile.length === 1) {
      setAllFlattenDisabled(true);
    }
    setShowModal(false);
    setGeneratedPdf(null);
    setProcessingMessage();
    setReady(false);
    setButtonDisabled(true);
  };
  const handleShareLink = () => {
    console.log("Share button clicked");
    setFlattenedPdfUrl(pdfBlobUrl);
    // Reset copy link status
    setCopyLinkSuccess(false);
    setShowShareModal(true);
    setShareModal(true);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(flattenedPdfUrl).then(() => {
      setCopyLinkSuccess(true);
    });
  };
  // calculate flatten pdf size based on select resolution
  useEffect(() => {}, []);
  const handleGetSize = () => {
    handleFlattenPdf();
  };
  const allFlattenPdf = async () => {
    if (selectedFile.length === 1) {
      setAllFlattenDisabled(true);
    }
    const filePromises = Array.from(selectedFile).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function () {
          const typedArray = new Uint8Array(reader.result);
          window.pdfjsLib
            .getDocument(typedArray)
            .promise.then(async function (pdf) {
              const numPages = pdf.numPages;
              const imageDataArray = [];
              // Convert each page to image
              for (let i = 1; i <= numPages; i++) {
                const imageData = await convertPageToImage(pdf, i);
                imageDataArray.push(imageData);
              }
              // Resolve with the array of image data for this file
              resolve(imageDataArray);
            });
        };
        reader.readAsArrayBuffer(file);
      });
    });
    // Wait for all file promises to resolve
    const allFilesImageData = await Promise.all(filePromises);
    // Flatten all files into a single PDF
    const flattenedPdf = createPdfFromImages(allFilesImageData.flat());
    // Get the size of the flattened PDF
    const flattenedPdfSize = (await flattenedPdf.output()).length;
    const flattenSize = formatFileSize(flattenedPdfSize);
    setFlattenedPdfSize(flattenSize);
    setFSize(false);
    // Create Blob and Blob URL
    const pdfBlob = new Blob([flattenedPdf.output("arraybuffer")], {
      type: "application/pdf",
    });
    const pdfBlobUrl1 = URL.createObjectURL(pdfBlob);
    // Save the final PDF in state
    setGeneratedPdf(flattenedPdf);
    setPdfBlobUrl(pdfBlobUrl1);
    setReady(true);
    // Update processing message for download
    setProcessingMessage("Your document is ready");
  };
  // this function is for flatten pdf with ZIP file
  const handleFlattenPdf = async () => {
    setShowResolutionModal(false);
    setShowModal(true);
    if (!selectedFile || selectedFile.length === 0) {
      return;
    }
    const flattenedPdfsArray = [];
    for (let i = 0; i < selectedFile.length; i++) {
      const file = selectedFile[i];
      const reader = new FileReader();
      const filePromise = new Promise((resolve) => {
        reader.onload = function () {
          const typedArray = new Uint8Array(reader.result);
          window.pdfjsLib
            .getDocument(typedArray)
            .promise.then(async function (pdf) {
              const numPages = pdf.numPages;
              const imageDataArray = [];
              // Convert each page to image
              for (let j = 1; j <= numPages; j++) {
                const imageData = await convertPageToImage(pdf, j);
                imageDataArray.push(imageData);
              }
              // Flatten PDF and add it to the array
              const flattenedPdf = createPdfFromImages(imageDataArray);
              flattenedPdfsArray.push({
                name: file.name.replace(".pdf", "_flattened.pdf"),
                content: flattenedPdf.output("blob"),
              });
              resolve();
            });
        };
        reader.readAsArrayBuffer(file);
      });
      await filePromise;
    }
    setFlattenedPdfs(flattenedPdfsArray);
    setCurrentPage(1);
    if (selectedFile.length > 1) {
      setAllFlattenDisabled(false);
    }
    allFlattenPdf();
  };
  // download ZIP file function
  const handleDownloadAll = () => {
    const zip = new JSZip();
    flattenedPdfs.forEach((pdf) => {
      zip.file(pdf.name, pdf.content);
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "FlattenedPDFs.zip");
    });
  };
  const handleDeletePdf = (index) => {
    // Copy the current state arrays
    const newSelectedFile = [...selectedFile];
    const newPdfImages = [...pdfImages];
    const newPdfFileNames = [...pdfFileNames];

    newSelectedFile.splice(index, 1);
    newPdfImages.splice(index, 1);
    newPdfFileNames.splice(index, 1);

    setSelectedFile(newSelectedFile);
    setPdfImages(newPdfImages);
    setPdfFileNames(newPdfFileNames);
    newSelectedFile.length === 0
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDropzoneBackground(true);
  };
  async function convertPageToImage(pdf, pageNumber) {
    return new Promise((resolve) => {
      pdf.getPage(pageNumber).then((page) => {
        const canvas = document.createElement("canvas");
        const AssignScaleFactor = scaleFactor;

        const viewPort = page.getViewport({ scale: AssignScaleFactor });
        canvas.width = viewPort.width;
        canvas.height = viewPort.height;
        const ctx = canvas.getContext("2d");

        const renderContext = {
          canvasContext: ctx,
          viewport: viewPort,
        };
        page.render(renderContext).promise.then(() => {
          const imageData = canvas.toDataURL("image/jpeg", 1.0);
          resolve(imageData);
        });
      });
    });
  }
  const createPdfFromImages = (imageDataArray) => {
    // eslint-disable-next-line no-undef
    const doc = new jsPDF("p", "mm", "a4");
    const margin = 10;

    imageDataArray.forEach((imageData, index) => {
      if (index > 0) {
        doc.addPage();
      }
      doc.addImage(
        imageData,
        "JPEG",
        margin,
        margin,
        doc.internal.pageSize.width - 2 * margin,
        doc.internal.pageSize.height - 2 * margin,
        undefined,
        "FAST",
        1
      );
    });
    return doc;
  };
  const handleCutModal = () => {
    setSelectedFile([]);
    setPdfImages([]);
    setPdfFileNames([]);
    setShowModal(false);
    setGeneratedPdf(null);
    setProcessingMessage();
    setReady(false);
    setButtonDisabled(false);
  };
  useEffect(() => {
    // Call the function to flatten PDF and calculate size
    if (fSize) {
      allFlattenPdf();
      console.log("Flattened PDF Size from useEffect:", flattenedPdfSize);
    }
    // Now you can access the size of the flattened PDF if needed
  }, [scaleFactor, fSize]);
  const handleContinue = () => {};
  const handleScaleFactorChange = (e) => {
    const selectedScaleFactor = parseInt(e.target.value, 10);
    setScaleFactor(selectedScaleFactor);
  };
  const handleOpenResolutionModal = () => {
    setShowResolutionModal(true);
  };
  const handleResolutionChange = (value) => {
    console.log("Value:", value);
    const scaleFactor = value / 100;
    setSelectedResolution(value);
    setScaleFactor(scaleFactor);
    setFSize(true);
    setHandleModal(true);
  };
  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };

  useEffect(() => {
    if (showModal || showResolutionModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showModal, showResolutionModal]);

  return (
    <>
      {selectedFile.length > 0 ? (
        <>
          {/* Render selected file details and conversion options */}
          {showResolutionModal && (
            <>
              <div
                className={`overlay ${showResolutionModal ? "show" : ""}`}
              ></div>
              <div
                className={` modal fade ${showResolutionModal ? "show" : ""}`}
                style={{
                  display: showResolutionModal ? "block" : "none",
                }}
                tabIndex="-1"
                // role="dialog"
                aria-labelledby="shareModal"
                aria-hidden="true"
              >
                <div className="modal-dialog  modal-dialog-centered">
                  <div className="modal-content border-0">
                    <div className="modal-header border-0">
                      <h5
                        className="modal-title fw-bold"
                        id="shareModal"
                        style={{ margin: "0 auto" }}
                      >
                        PDF Quality
                      </h5>
                      <button
                        type="button"
                        className="close modal-close-btn border-0 px-3 fw-bold"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => setShowResolutionModal(false)}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="d-flex justify-content-between flex-column resolution-slider-container">
                        <label className="mb-3 fw-bolder">
                          Select Resolution (DPI)
                        </label>
                        <div className="d-flex justify-content-between range-labels">
                          <span>Min {100}</span>
                          <span>Max {300} </span>
                        </div>

                        <CustomizedSlider
                          selectedResolution={selectedResolution}
                          handleResolutionChange={handleResolutionChange}
                        />

                        <div className="mt-2 text-center selected-range">
                          <span className="me-2">{selectedResolution} DPI</span>
                          <span>{flattenedPdfSize}</span>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer border-0 py-4">
                      <button
                        type="button"
                        class="popup-btn m-auto"
                        onClick={handleFlattenPdf}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container-fluid text-center fw-semibold px-0">
            <div className="main-pdf-body pt-2 pb-4 mt-4">
              <div
                className="container"
                style={{
                  border: "2px dotted #00bb87",
                  padding: "0px",
                  marginTop: "20px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="border text-center d-flex align-items-center justify-content-center drop-area"
                  style={{
                    height: "200px",
                    cursor: "pointer",
                    background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
                  }}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={() => fileInputRef.current.click()}
                >
                  <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
                    Select or drop your files here <br />
                    <i
                      className="fa-solid fa-cloud-arrow-up"
                      style={{ fontSize: "5rem", color: "#54bc8b" }}
                    ></i>
                  </p>
                </div>
                <input
                  type="file"
                  id="fileInput"
                  accept="application/pdf"
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              </div>

              <div
                className={`container mt-3 mb-5 px-0 ${
                  !isMobile ? "pdf-container" : ""
                }`}
              >
                {pdfImages.map((canvas, index) => (
                  <div
                    key={index}
                    className={`mt-3 ${!isMobile ? "pdf-card" : "mobile-card"}`}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    style={{ border: "" }}
                  >
                    {isMobile ? (
                      <>
                        {hoveredIndex === index && (
                          <button
                            className="image-delete-btn mt-2"
                            onClick={() => handleDeletePdf(index)}
                            style={{ right: "-8px" }}
                          >
                            <i
                              className="fa-solid fa-xmark"
                              style={{
                                margin: "0px",
                                padding: "0.33rem 0 0 0",
                                width: "25px",
                                height: "25px",
                              }}
                            ></i>
                          </button>
                        )}
                        <div
                          className="row px-0 mx-0"
                          style={{
                            boxShadow: "0 2px 5px 2px rgba(0,0,0,0.09)",
                            borderRadius: "7px",
                          }}
                        >
                          <div className="col-12 py-2 d-flex justify-content-between">
                            <img
                              src={pdfIcon1}
                              alt="pdf-img"
                              className="pdf-image p-0"
                            />

                            <p class="name fw-bold">
                              {pdfFileNames[index].name}
                              <br />
                              {formatFileSize(pdfFileNames[index].size)}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <canvas
                          className={`pdf-canvas-${index}`}
                          width={width}
                          height={height}
                          style={{
                            boxShadow:
                              "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                            marginTop: "0.5rem",
                          }}
                        ></canvas>
                        {hoveredIndex === index && (
                          <button
                            className="canvas-delete-btn mt-2"
                            onClick={() => handleDeletePdf(index)}
                          >
                            <i
                              class="fa-solid fa-xmark"
                              style={{
                                margin: "0px",
                                padding: "0.33rem 0 0 0",
                                width: "25px",
                                height: "25px",
                              }}
                            ></i>
                          </button>
                        )}
                        <div class="name-box">
                          <p class="name">
                            {pdfFileNames[index].name} <br />
                            {formatFileSize(pdfFileNames[index].size)}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={`overlay ${showModal ? "show" : ""}`}></div>
            {/* modal code 2 */}
            <div
              className={`my-modal modal fade ${showModal ? "show" : ""}`}
              style={{ display: showModal ? "block" : "none" }}
              tabIndex="-1"
              role="dialog"
              aria-labelledby="processingModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl  my-modal">
                <div className="modal-content border-0 my-small-modal">
                  <div className="modal-header border-0 my-modal-header p-0 p-sm-1">
                    <h2
                      className="modal-title my-h2"
                      id="processingModal"
                      style={{ margin: "0 auto" }}
                    >
                      {ready ? (
                        <i class="fa-regular fa-circle-check"></i>
                      ) : (
                        <></>
                      )}{" "}
                      <span style={{ fontWeight: "400" }}>
                        {processingMessage}
                      </span>
                    </h2>
                    <button
                      type="button"
                      className="close modal-close-btn fw-bold border-0"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCutModal}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body fw-semibold">
                    <>
                      {!generatedPdf && (
                        <>
                          <div className="d-flex text-center justify-content-center align-items-center">
                            <div
                              class="spinner-border text-success"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                            <h2 className="fw-bold mx-5 my-4">
                              Your Task is processing{" "}
                            </h2>
                          </div>
                          <h4 style={{ color: "#9aa5aa", fontWeight: 700 }}>
                            Please Wait ...
                          </h4>
                        </>
                      )}
                    </>
                    {generatedPdf && (
                      <div className="d-flex">
                        <div
                          className="card mx-3"
                          style={{
                            width: "100%",
                            maxHeight: "900px",
                          }}
                        >
                          <iframe
                            src={pdfBlobUrl}
                            title={`Page ${currentPage}`}
                            width="100%"
                            height="900px"
                            frameBorder="0"
                            style={{
                              width: "100%",
                              border: "none",
                              height: "900px",
                            }}
                          ></iframe>
                          <>
                            {/* modal 1 */}
                            {shareModal && (
                              <>
                                <div
                                  className={`overlay ${
                                    showShareModal ? "show" : ""
                                  }`}
                                ></div>
                                <div
                                  className={` modal fade ${
                                    showShareModal ? "show" : ""
                                  }`}
                                  style={{
                                    display: showShareModal ? "block" : "none",
                                  }}
                                  tabIndex="-1"
                                  // role="dialog"
                                  aria-labelledby="shareModal"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog  modal-dialog-centered">
                                    <div className="modal-content border-0">
                                      <div className="modal-header border-0">
                                        <h5
                                          className="modal-title fw-bold"
                                          id="shareModal"
                                          style={{ margin: "0 auto" }}
                                        >
                                          Share Flattened PDF Link
                                        </h5>
                                        <button
                                          type="button"
                                          className="close modal-close-btn border-0 px-3 fw-bold"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          onClick={() =>
                                            setShowShareModal(false)
                                          }
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <p className="mb-3">
                                          {flattenedPdfUrl}
                                        </p>
                                        <div className="d-flex">
                                          <button
                                            type="button"
                                            className="pdf-btn fw-bold"
                                            onClick={handleCopyLink}
                                          >
                                            {copyLinkSuccess
                                              ? "Link Copied!"
                                              : "Copy Link"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer border-0 justify-content-between">
                    {ready && (
                      <>
                        <div className="footer-btn ms-sm-auto">
                          <button
                            type="button"
                            className="pdf-btn fw-bold me-2"
                            onClick={handleBackToEditing}
                          >
                            Back to Editing
                          </button>
                          <button
                            type="button"
                            className="pdf-btn fw-bold me-2"
                            onClick={handleShareLink}
                            style={{ width: "149px" }}
                          >
                            Share
                          </button>

                          <>
                            {allFlattenDisabled ? (
                              <button
                                type="button"
                                class="pdf-btn fw-bold me-2"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Choose more than 1 PDF to merge"
                                disabled={allFlattenDisabled}
                                onClick={handleDownload}
                                style={{ width: "149px" }}
                              >
                                Merge PDF
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="pdf-btn fw-bold me-2"
                                onClick={handleDownload}
                              >
                                Merge PDF
                              </button>
                            )}
                          </>
                          <button
                            type="button"
                            className="pdf-btn fw-bold me-2"
                            onClick={handleDownloadAll}
                          >
                            Download PDF
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="main-pdf-body pt-2 pb-4 mt-5 mb-5">
            <div
              className="container"
              style={{
                border: "2px dotted #00bb87",
                padding: "0px",
                marginTop: "20px",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <div
                className="border text-center d-flex align-items-center justify-content-center drop-area"
                style={{
                  height: "200px",
                  cursor: "pointer",
                  background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
                }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => fileInputRef.current.click()} // Add this line
              >
                <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
                  Select or drop your files here <br />
                  <i
                    className="fa-solid fa-cloud-arrow-up"
                    style={{ fontSize: "5rem", color: "#54bc8b" }}
                  ></i>
                </p>
              </div>
              <input
                type="file"
                id="fileInput"
                accept="application/pdf"
                multiple
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </>
      )}
      <Footer
        onFlattenPdfClick={handleOpenResolutionModal}
        isDisabled={isButtonDisabled}
        selectedFile={selectedFile}
        onOpenResolutionModal={handleOpenResolutionModal}
        scaleFactor={scaleFactor} // Pass scaleFactor to Footer
        scaleFactorOptions={[1, 2, 3]} // Set available options
        onScaleFactorChange={handleScaleFactorChange} // Pass handler
      />
    </>
  );
};
export default Pdf;
