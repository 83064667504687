import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import JSZip from "jszip";
import { PDFDocument } from "pdf-lib";
import "./SplitPDFModal.css";
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  margin: "0 auto",
};
const thumb = {
  display: "flex",
  borderRadius: 2,
  width: 180,
  height: 230,
  boxSizing: "border-box",
  boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
};
const SplitPDFModal = ({
  isOpen,
  totalPages,
  selectedFile,
  setIsOpen,
  selectedPages,
  setSelectedPages,
  splitPDF,
  setCustomPagesInput,
  customPagesInput,
  generateSinglePDF,
  setShowModal,
  imageUrls,
  mergePDF,
  setCustomSelectedPages,
}) => {
  const [splitOption, setSplitOption] = useState("range");
  const [disabled, setDisabled] = useState(false);
  const [dummy1, setDummy1] = useState(false);
  const [pagesToRender, setPagesToRender] = useState([]);
  const [firstCardPages, setFirstCardPages] = useState([]);
  const [secondCardPages, setSecondCardPages] = useState([]);
  const [SelectPagesToRender, setSelectPagesToRender] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("splitByRange");
  const [selectedRadioFixed, setSelectedRadioFixed] = useState("fixedRange1");
  const [buttonRendering, setButtonRendering] = useState(false);
  const [calculatePdf, setCalculatePdf] = useState(totalPages);
  const [boolTemp, setBoolTemp] = useState(true);
  const [extractAllPages, setExtractAllPages] = useState(false);
  const [selectBool, setSelectBool] = useState(false);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [fromPage, setFromPage] = useState(1);
  const [toPage, setToPage] = useState(totalPages); // Default to page 1
  const [addCustomRange, setAddCustomRange] = useState(false);
  const [addCustomRangePages, setAddCustomRangePages] = useState([totalPages]);
  const [addFromPage, setAddFromPage] = useState(totalPages);
  const [addToPage, setAddToPage] = useState(totalPages);
  const [renderFixed, setRenderFixed] = useState(true);
  const [customRanges, setCustomRanges] = useState([]);
  const [selectBooleanVal, setSelectBooleanVal] = useState(false);
  const [firstTimeCustomSelected, setFirstTimeCustomSelected] = useState(true);
  const [renderRangePDF, setRenderRangePDF] = useState(false);
  const [handleSplitBoolean, setHandleSplitBoolean] = useState(false);
  const [addRangeBoolean, setAddRangeBoolean] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const updateSelectedPages = () => {
    setCustomSelectedPages([fromPage, toPage]);
  };

  useEffect(() => {
    updateSelectedPages();
  }, []);

  useEffect(() => {
    if (selectBool) {
      updateCardPages();
    }
  }, [selectedPages, selectBool]);
  const updateCardPages = () => {
    if (selectedPages === 2) {
      setFirstCardPages([1, 2]);
      setSecondCardPages([3, totalPages]);
      setCalculatePdf(2);
    } else if (selectedPages === totalPages) {
      setFirstCardPages([1, totalPages]);
      setSecondCardPages([]);
      setCalculatePdf(1);
    } else {
      let pagesPerCard = Math.ceil(totalPages / selectedPages);
      let firstCardEnd = selectedPages;
      setFirstCardPages([1, firstCardEnd]);
      let secondCardStart = firstCardEnd + 1;
      let secondCardEnd = Math.min(
        secondCardStart + pagesPerCard - 1,
        totalPages
      );
      setSecondCardPages([secondCardStart, secondCardEnd]);
      setCalculatePdf(2);
    }
  };

  useEffect(() => {
    // Generate array of page numbers to render
    setExtractAllPages(true);
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    setPagesToRender(pages);
  }, [totalPages]);

  const handleSelectedPagesChange = (e) => {
    const selectedPage = Number(e.target.value);
    setSelectedPages(selectedPage);
    setSelectBool(true);
  };

  useEffect(() => {
    if (splitOption === "extract") {
      setSelectedPages(1);
      setSelectedRadio("splitByRange");
      setCalculatePdf(totalPages);
      setSelectBool(false);
    }
    if (splitOption === "range") {
      setBoolTemp(true);
      setCalculatePdf(totalPages);
      setSelectedRadioFixed("fixedRange1");
    }
  }, [splitOption]);

  const handleSplitOptionChange = (option) => {
    setCalculatePdf(totalPages);
    setSplitOption(option);
    setDummy1(false);
    if (option === "range") {
      setButtonRendering(false);
      setSelectBool(true);
      setCalculatePdf(totalPages);
      setDisabled(false);
      setSelectBooleanVal(false);
      setHandleSplitBoolean(false);
      setRenderFixed(true);
    }
    if (option === "extract") {
      setDisabled(false);
      setButtonRendering(false);
      setCalculatePdf(totalPages);
      setSelectBooleanVal(true);
      setRenderFixed(false);
      setAddRangeBoolean(false);
      setShowCustomRange(false);
    }
    if (option === "extractAll") {
      setDisabled(false);
      setDummy1(false);
      setSelectedPages(1);
    }
    if (option === "selectPage") {
      setDummy1(true);
      setDisabled(false);
    }
  };
  const handleSplitClick = () => {
    if (splitPDF) {
      splitPDF();
    }
  };
  const handleMergeClick = () => {
    if (mergePDF) {
      mergePDF();
    }
  };
  const handleInputClick = () => {
    if (generateSinglePDF) {
      generateSinglePDF();
    }
  };
  const handleCustomRange = () => {
    setShowCustomRange(!showCustomRange);
    setCalculatePdf(1);
  };
  const handleClick = () => {
    setIsOpen(false);
    setShowModal(false);
  };
  const handleOtherClick = (val) => {
    if (val === "extractAll") {
      setButtonRendering(false);
      setCalculatePdf(totalPages);
      setExtractAllPages(true);
      setSelectedRadio();
      setDisabled(false);
      setDummy1(false);
      setSelectedPages(1);
      setBoolTemp(true);
      setSelectedRadio("splitByRange");
      setSelectBooleanVal(true);
    }
    if (val === "selectPage") {
      setButtonRendering(true);
      setCustomPagesInput("");
      setSelectPagesToRender([]);
      setCalculatePdf(0);
      setDummy1(true);
      setDisabled(false);
      setBoolTemp(false);
      setSelectedRadio(val);
      setSelectBooleanVal(false);
    }
  };

  // Function to handle change in 'from' page dropdown
  const handleFromPageChange = (e) => {
    const value = parseInt(e.target.value);
    if (value <= toPage) {
      setFromPage(value);
      setCustomSelectedPages([value, toPage]);
      if (value === toPage) {
        setCustomSelectedPages([value]);
      }
    }
    setCalculatePdf(1);
  };
  // Function to handle change in 'to' page dropdown
  const handleToPageChange = (e) => {
    const a = parseInt(e.target.value);
    if (a === fromPage) {
      setToPage(a);
      setCustomSelectedPages([a]);
    }
    if (a > fromPage && a <= totalPages) {
      setToPage(a);
      setCustomSelectedPages([fromPage, a]);
    }
    setCalculatePdf(1);
  };

  const handleAddRange = () => {
    setAddCustomRange(true);
    const newRange = {
      id: uuidv4(),
      fromPage: 1,
      toPage: totalPages,
    };
    setCustomRanges([...customRanges, newRange]);
  };

  const handleAddPageChangeStart = (e, rangeId) => {
    const value = parseInt(e.target.value);
    const updatedRanges = customRanges.map((range) => {
      if (range.id === rangeId) {
        const newValue = value <= range.toPage ? value : range.fromPage;

        return {
          ...range,
          fromPage: newValue,
        };
      }
      return range;
    });
    const newCustomRangePages = updatedRanges.map((range) => ({
      fromPage: range.fromPage,
      toPage: range.toPage,
    }));
    setAddCustomRangePages(newCustomRangePages);

    setCustomRanges(updatedRanges);
  };

  const handleAddPageChangeEnd = (e, rangeId) => {
    const value = parseInt(e.target.value);
    const updatedRanges = customRanges.map((range) => {
      if (range.id === rangeId) {
        return {
          ...range,
          toPage: Math.max(value, range.fromPage),
        };
      }
      return range;
    });

    setCustomRanges(updatedRanges);
  };

  const rangeHandlingChange = (val) => {
    if (val === "custom") {
      setRenderFixed(false);
      setShowCustomRange(true);
      setAddCustomRange(true);
      setSelectedRadioFixed(val);
      setRenderRangePDF(true);
      setHandleSplitBoolean(true);
      setAddRangeBoolean(true);

      if (firstTimeCustomSelected) {
        handleAddRange();
      }
      setFirstTimeCustomSelected(false);
    }
    if (val === "fixed") {
      setRenderFixed(true);
      setShowCustomRange(false);
      setSelectedRadioFixed("fixedRange1");
      setRenderRangePDF(false);
      setHandleSplitBoolean(false);
      setAddRangeBoolean(false);
    }
  };
  const handleDeleteRange = (id) => {
    if (customRanges.length > 1) {
      setCustomRanges((prevRanges) =>
        prevRanges.filter((range) => range.id !== id)
      );
    }
  };
  // HERE IS THE CODE OF ALL SPLIT PDF FILE
  const handleSplitClicked = async () => {
    const zip = new JSZip();
    const fileBytes = await selectedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(fileBytes);
    for (let i = 0; i < customRanges.length; i++) {
      const { fromPage, toPage } = customRanges[i];
      const newDoc = await PDFDocument.create();
      for (let k = fromPage - 1; k < toPage; k++) {
        const [copiedPage] = await newDoc.copyPages(pdfDoc, [k]);
        newDoc.addPage(copiedPage);
      }
      const newPdfBytes = await newDoc.save();
      zip.file(`a2z-SplitPdf${fromPage}-${toPage}.pdf`, newPdfBytes);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const zipUrl = URL.createObjectURL(zipBlob);
    const a = document.createElement("a");
    a.href = zipUrl;
    a.download = `a2z-SplitPdf_${selectedPages}.zip`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  // HERE IS THE CODE OF ALL MERGE PDF
  const handleMergeClicked = async () => {
    const mergedDoc = await PDFDocument.create();

    const fileBytes = await selectedFile.arrayBuffer();
    const pdfDoc = await PDFDocument.load(fileBytes);
    let mergedPdfBlob;

    for (let i = 0; i < customRanges.length; i++) {
      const { fromPage, toPage } = customRanges[i];
      const copiedPages = await mergedDoc.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      for (let j = fromPage - 1; j < toPage; j++) {
        mergedDoc.addPage(copiedPages[j]);
      }
    }
    const mergedPdfBytes = await mergedDoc.save();
    mergedPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(mergedPdfBlob);
    downloadLink.download = "merged_pdf.pdf";
    downloadLink.click();
  };
  const renderCustomRanges = () => {
    return customRanges.map((range, index) => {
      const startPage = range.fromPage;
      const endPage = range.toPage;
      const pagesToShow =
        endPage === startPage ? [startPage] : [startPage, endPage];
      return (
        <div key={index} className="custom-range">
          <div
            className="d-flex p-3 mt-3 ms-2 justify-content-center"
            style={{ border: "2px dashed grey" }}
          >
            {pagesToShow.map((pageNumber, index) => (
              <React.Fragment key={index}>
                <div>
                  <div style={thumb}>
                    <div style={thumbInner}>
                      <img
                        src={imageUrls[pageNumber - 1]}
                        alt={`Page ${pageNumber}`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <span className="" style={{ fontSize: "13px" }}>
                        {pageNumber}
                      </span>
                    </div>
                  </div>
                </div>
                {index < pagesToShow.length - 1 && (
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col text-center">
                      <span>........</span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {isOpen && (
        <>
          <div className={`overlay ${isOpen ? "show" : ""}`}></div>
          <div
            className={` modal fade ${isOpen ? "show" : ""}`}
            style={{
              display: isOpen ? "block" : "none",
            }}
            tabIndex="-1"
            aria-labelledby="shareModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content " style={{ height: "90vh" }}>
                <div className="modal-header border-0">
                  <h5
                    className="modal-title p-0 fs-3"
                    id="shareModal"
                    style={{ margin: "0 auto", fontWeight: "550" }}
                  >
                    Split PDF
                  </h5>
                  <button
                    type="button"
                    className="close modal-close-btn border-0 px-3 fw-bold"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClick}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul
                    class="nav justify-content-center nav-pills"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active fw-bold"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#home"
                        type="button"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => handleSplitOptionChange("range")}
                      >
                        Split by Range
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link fw-bold"
                        id="profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => handleSplitOptionChange("extract")}
                      >
                        Extract Pages
                      </button>
                    </li>
                  </ul>

                  <div>
                    {splitOption === "range" && (
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-2 border-end">
                            <div className="form-check mb-3">
                              <input
                                type="radio"
                                id="splitByRangeFixed" // Unique ID for fixed range
                                name="splitOption"
                                className="form-check-input"
                                checked={selectedRadioFixed === "fixedRange1"}
                                onChange={() => rangeHandlingChange("fixed")}
                              />
                              <label
                                htmlFor="splitByRangeFixed"
                                className="form-check-label"
                              >
                                Fixed Range
                              </label>
                            </div>
                            <div className="form-check mb-3">
                              <input
                                type="radio"
                                id="splitByRangeCustom" // Unique ID for custom range
                                name="splitOption"
                                className="form-check-input"
                                // checked={selectedRadio === "splitByRange"}
                                onChange={() => rangeHandlingChange("custom")}
                              />
                              <label
                                htmlFor="splitByRangeCustom"
                                className="form-check-label"
                              >
                                Custom Range
                              </label>
                            </div>
                            {renderFixed && (
                              <>
                                <div className="text-center px-0 mt-3">
                                  <span>Split PDF into pages of </span>
                                </div>
                                <div className="form-group px-0 mt-3">
                                  <select
                                    id="startValue"
                                    className="form-select"
                                    value={selectedPages}
                                    onChange={handleSelectedPagesChange}
                                  >
                                    {[...Array(totalPages).keys()].map(
                                      (page) => (
                                        <option key={page + 1} value={page + 1}>
                                          {page + 1}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </>
                            )}
                            {showCustomRange && handleSplitBoolean && (
                              <div className=" custom-range-container">
                                {customRanges.map((range, index) => (
                                  <div
                                    key={range.id}
                                    className="border p-1 mb-3"
                                    onMouseOver={() => setHoveredIndex(index)}
                                    onMouseOut={() => setHoveredIndex(-1)}
                                  >
                                    <span
                                      className="d-flex justify-content-center py-1"
                                      style={{ fontSize: "0.9rem" }}
                                    >
                                      Range{" "}
                                      <span
                                        className="px-1 ms-1"
                                        style={{
                                          background: "#f0f0f0",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        {index + 1}
                                      </span>
                                    </span>
                                    <div className="d-flex justify-content-between mb-4">
                                      <label htmlFor={`fromPage-${range.id}`}>
                                        From Page:
                                      </label>
                                      <select
                                        id={`fromPage-${range.id}`}
                                        value={range.fromPage}
                                        onChange={(e) =>
                                          handleAddPageChangeStart(e, range.id)
                                        }
                                      >
                                        {Array.from(
                                          { length: totalPages },
                                          (_, index) => (
                                            <option
                                              key={index + 1}
                                              value={index + 1}
                                            >
                                              {index + 1}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <div className="d-flex justify-content-between mb-4">
                                      <label htmlFor={`toPage-${range.id}`}>
                                        To Page:
                                      </label>
                                      <select
                                        id={`toPage-${range.id}`}
                                        value={range.toPage}
                                        onChange={(e) =>
                                          handleAddPageChangeEnd(e, range.id)
                                        }
                                      >
                                        {Array.from(
                                          { length: totalPages },
                                          (_, index) => (
                                            <option
                                              key={index + 1}
                                              value={index + 1}
                                            >
                                              {index + 1}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      {hoveredIndex === index && (
                                        <button
                                          className="p-0 m-0"
                                          onClick={() =>
                                            handleDeleteRange(range.id)
                                          }
                                          style={{
                                            width: "45px",
                                            border: "none",
                                            background: "#0b8",
                                            color: "#fff",
                                            borderRadius: "4px",
                                          }}
                                        >
                                          <i
                                            className="fa-solid fa-xmark"
                                            style={{
                                              margin: "0px",
                                              width: "25px",
                                              height: "18px",
                                              fontWeight: "700",
                                            }}
                                          ></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-10">
                            {showCustomRange && (
                              <>
                                {/* Render the card based on the selected pages */}
                                <div className=" d-flex justify-content-center flex-wrap">
                                  {addCustomRange && handleSplitBoolean && (
                                    <>{renderCustomRanges()}</>
                                  )}
                                </div>{" "}
                              </>
                            )}
                            {renderFixed && (
                              <>
                                {selectedPages !== 1 &&
                                  renderFixed &&
                                  splitOption === "range" && (
                                    <div
                                      className={`d-flex flex-wrap ${
                                        secondCardPages.length > 0
                                          ? "justify-content-around"
                                          : "justify-content-center"
                                      }`}
                                      style={{ overflowX: "auto" }}
                                    >
                                      {[
                                        ...Array(
                                          Math.ceil(totalPages / selectedPages)
                                        ),
                                      ].map((_, cardIndex) => {
                                        const startPage =
                                          cardIndex * selectedPages + 1;
                                        const endPage = Math.min(
                                          (cardIndex + 1) * selectedPages,
                                          totalPages
                                        );
                                        return (
                                          <div
                                            key={cardIndex}
                                            className="d-flex mt-4 p-3 pb-0 justify-content-center"
                                            style={{
                                              border: "2px dashed grey",
                                            }}
                                          >
                                            <div className="">
                                              <div style={thumb}>
                                                <div style={thumbInner}>
                                                  <img
                                                    src={
                                                      imageUrls[startPage - 1]
                                                    }
                                                    alt={`Page ${startPage}`}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col text-center">
                                                  <span
                                                    className=""
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {startPage}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            {startPage !== endPage && (
                                              <React.Fragment>
                                                <div className="row d-flex align-items-center justify-content-center">
                                                  <div className="col text-center">
                                                    <span>........</span>
                                                  </div>
                                                </div>
                                                <div className="">
                                                  <div style={thumb}>
                                                    <div style={thumbInner}>
                                                      <img
                                                        src={
                                                          imageUrls[endPage - 1]
                                                        }
                                                        alt={`Page ${endPage}`}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col text-center">
                                                      <span
                                                        className=""
                                                        style={{
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        {endPage}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}

                                {selectedPages === 1 && (
                                  <div className="d-flex mt-2 flex-wrap justify-content-center">
                                    {pagesToRender.map((pageNumber) => (
                                      <div
                                        key={pageNumber}
                                        className="m-3 p-3 pb-0"
                                        style={{ border: "2px dashed grey" }}
                                      >
                                        <div style={thumb}>
                                          <div style={thumbInner}>
                                            <img
                                              src={imageUrls[pageNumber - 1]}
                                              alt={`Page ${pageNumber}`}
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col text-center">
                                            <span
                                              className=""
                                              style={{ fontSize: "13px" }}
                                            >
                                              {pageNumber}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-2 border-end">
                          {splitOption === "extract" && (
                            <>
                              <div>
                                <div className="form-check mb-4">
                                  <input
                                    type="radio"
                                    id="splitByRange"
                                    name="splitOption"
                                    className="form-check-input"
                                    checked={selectedRadio === "splitByRange"}
                                    onChange={() =>
                                      handleOtherClick("extractAll")
                                    }
                                  />
                                  <label
                                    htmlFor="splitByRange"
                                    className="form-check-label"
                                  >
                                    Extract All Pages
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="radio"
                                    id="extractPages"
                                    name="splitOption"
                                    className="form-check-input"
                                    onChange={() =>
                                      handleOtherClick("selectPage")
                                    }
                                  />
                                  <label
                                    htmlFor="extractPages"
                                    className="form-check-label"
                                  >
                                    Select Pages
                                  </label>
                                </div>
                              </div>
                              {dummy1 && (
                                <div className=" mt-4">
                                  <div
                                    htmlFor="customPagesInput"
                                    className=" text-center px-0 mb-4"
                                  >
                                    Pages to extract :
                                  </div>
                                  <div className="">
                                    <input
                                      type="text"
                                      id="customPagesInput"
                                      className="form-control"
                                      value={customPagesInput}
                                      placeholder="example: 1,2,3"
                                      onChange={(e) => {
                                        const inputPages = e.target.value
                                          .split(",")
                                          .map((page) => parseInt(page.trim()))
                                          .filter(
                                            (page) =>
                                              !isNaN(page) &&
                                              page >= 1 &&
                                              page <= totalPages
                                          );
                                        setCustomPagesInput(e.target.value);
                                        setSelectPagesToRender(inputPages);
                                        setCalculatePdf(inputPages.length);
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className="col-sm-10">
                          <div className="overflow-container">
                            <>
                              {selectedPages !== 1 &&
                                renderFixed &&
                                splitOption === "extract" && (
                                  <div
                                    className={`d-flex ${
                                      secondCardPages.length > 0
                                        ? "justify-content-around"
                                        : "justify-content-center"
                                    }`}
                                    style={{ overflowX: "auto" }}
                                  >
                                    <div
                                      className="d-flex mt-4 p-3 pb-0 justify-content-center"
                                      style={{ border: "2px dashed grey" }}
                                    >
                                      {firstCardPages.map(
                                        (pageNumber, index) => (
                                          <React.Fragment key={pageNumber}>
                                            <div className="">
                                              <div style={thumb}>
                                                <div style={thumbInner}>
                                                  <img
                                                    src={
                                                      imageUrls[pageNumber - 1]
                                                    }
                                                    alt={`Page ${pageNumber}`}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col text-center">
                                                  <span
                                                    className=""
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {pageNumber}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            {index <
                                              firstCardPages.length - 1 && (
                                              <div className="row d-flex align-items-center justify-content-center">
                                                <div className="col text-center">
                                                  <span>........</span>
                                                </div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                    <div
                                      className="d-flex mt-4 justify-content-center p-3 pb-0"
                                      style={{
                                        border:
                                          secondCardPages.length > 0
                                            ? "2px dashed grey"
                                            : null,
                                      }}
                                    >
                                      {secondCardPages.map(
                                        (pageNumber, index) => (
                                          <React.Fragment key={pageNumber}>
                                            <div className="">
                                              <div style={thumb}>
                                                <div style={thumbInner}>
                                                  <img
                                                    src={
                                                      imageUrls[pageNumber - 1]
                                                    }
                                                    alt={`Page ${pageNumber}`}
                                                  />
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col text-center">
                                                  <span
                                                    className=""
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {pageNumber}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            {index <
                                              secondCardPages.length - 1 && (
                                              <div className="row d-flex align-items-center justify-content-center">
                                                <div className="col text-center">
                                                  <span>........</span>
                                                </div>
                                              </div>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              {selectedPages === 1 && selectBooleanVal && (
                                <div className="d-flex mt-2 flex-wrap justify-content-center">
                                  {pagesToRender.map((pageNumber) => (
                                    <div
                                      key={pageNumber}
                                      className="m-3 p-3 pb-0"
                                      style={{ border: "2px dashed grey" }}
                                    >
                                      <div style={thumb}>
                                        <div style={thumbInner}>
                                          <img
                                            src={imageUrls[pageNumber - 1]}
                                            alt={`Page ${pageNumber}`}
                                          />
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col text-center">
                                          <span
                                            className=""
                                            style={{ fontSize: "13px" }}
                                          >
                                            {pageNumber}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </>
                          </div>
                          <>
                            {dummy1 && (
                              <div className="d-flex mt-2 flex-wrap justify-content-center">
                                {SelectPagesToRender.map((pageNumber) => (
                                  <div
                                    key={pageNumber}
                                    className="m-3 p-3 pb-0"
                                    style={{ border: "2px dashed grey" }}
                                  >
                                    <div style={thumb}>
                                      <div style={thumbInner}>
                                        <img
                                          src={imageUrls[pageNumber - 1]}
                                          alt={`Page ${pageNumber}`}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col text-center">
                                        <span
                                          className=""
                                          style={{ fontSize: "13px" }}
                                        >
                                          {pageNumber}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="justify-content-center d-flex fw-semibold mt-2">
                    {buttonRendering ? (
                      customPagesInput ? (
                        <>{calculatePdf} PDFs will be generated</>
                      ) : null
                    ) : renderRangePDF ? (
                      <></>
                    ) : (
                      <>
                        {totalPages % selectedPages === 0
                          ? totalPages / selectedPages
                          : Math.floor(totalPages / selectedPages) + 1}{" "}
                        PDFs will be generated
                      </>
                    )}
                  </span>
                </div>
                <div class="modal-footer">
                  {buttonRendering ? (
                    <div className="ms-auto">
                      <button
                        type="button"
                        class=" px-5 pdf-btn me-2"
                        onClick={handleInputClick}
                        disabled={customPagesInput ? false : true}
                        style={{
                          borderRadius: "4px",
                        }}
                      >
                        Split PDF
                      </button>
                      <button
                        type="button"
                        class=" px-5 pdf-btn ms-1"
                        onClick={handleMergeClick}
                        disabled={customPagesInput ? false : true}
                        style={{
                          borderRadius: "4px",
                        }}
                      >
                        Merge PDF
                      </button>
                    </div>
                  ) : showCustomRange ? (
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "100%" }}
                    >
                      {addRangeBoolean && (
                        <button
                          onClick={handleAddRange}
                          className="pdf-btn"
                          style={{
                            borderRadius: "4px",
                          }}
                        >
                          Add Range
                        </button>
                      )}

                      <div>
                        <button
                          type="button"
                          class=" pdf-btn me-2"
                          onClick={handleSplitClicked}
                          disabled={disabled}
                          style={{
                            borderRadius: "4px",
                          }}
                        >
                          Split PDF
                        </button>
                        {customRanges.length > 1 && (
                          <button
                            type="button"
                            class=" pdf-btn ms-2"
                            onClick={handleMergeClicked}
                            disabled={disabled}
                            style={{
                              borderRadius: "4px",
                            }}
                          >
                            Merge PDF
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      class="ms-auto px-5 pdf-btn"
                      onClick={handleSplitClick}
                      disabled={disabled}
                      style={{
                        borderRadius: "4px",
                      }}
                    >
                      Split PDF
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SplitPDFModal;
