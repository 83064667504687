import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CompressPdf from "./components/CompressPdf";
import Header from "./components/Header";
import BodyHeading from "./components/BodyHeading";
import Pdf from "./components/Pdf";
import ImgToPdf from "./components/ImgToPdf";
import PdfToImg from "./components/PdfToImg";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import MergePdf from "./components/MergePdf";
import SplitPDF from "./components/SplitPDF";

function App() {
  const [activeTool, setActiveTool] = useState("allTools");
  const headingText = [
    {
      id: "allTools",
      heading: "Flatten PDF",
      subHeading: `Secure your fillable PDFs, simplify data input – one-step print and scan.`,
    },
    {
      id: "compress",
      heading: "Compress PDF",
      subHeading: "Reduce the size of your PDF files without losing quality.",
    },
    {
      id: "imgToPdf",
      heading: "Image To PDF",
      subHeading:
        "Convert images to PDF with ease. Choose your images and get a PDF.",
    },
    {
      id: "pdfToImg",
      heading: "PDF To Image",
      subHeading:
        "Extract images from your PDF files. Easily convert PDF to image files.",
    },
    {
      id: "mergePdf",
      heading: "Merge PDF",
      subHeading:
        "Combine multiple PDF files into a single document effortlessly with our PDF merging tool.",
    },
    {
      id: "splitPdf",
      heading: "Split PDF",
      subHeading:
        "Segmentation Simplified: Dividing PDFs into smaller units for streamlined sharing and processing.",
    },
  ];
  const selectedHeading = headingText.find((item) => item.id === activeTool);

  const handleCompressClick = () => {
    setActiveTool("compress");
  };
  const handleAllToolsClick = () => {
    setActiveTool("allTools");
  };
  const handleImgToPdfClick = () => {
    setActiveTool("imgToPdf");
  };
  const handlePdfToImageClick = () => {
    setActiveTool("pdfToImg");
  };
  const handleMergePdfClick = () => {
    setActiveTool("mergePdf");
  };
  const handleSplitPdfClick = () => {
    setActiveTool("splitPdf");
  };
  return (
    <BrowserRouter>
      <Header
        onAllToolsClick={handleAllToolsClick}
        onCompressClick={handleCompressClick}
        onImgToPdfClick={handleImgToPdfClick}
        onPdfToImageClick={handlePdfToImageClick}
        onMergePdfClick={handleMergePdfClick}
        onSplitPdfClick={handleSplitPdfClick}
      />
      <BodyHeading headingData={selectedHeading} />
      <Routes>
        <Route path="/" element={<Pdf />} />
        <Route path="compress-pdf" element={<CompressPdf />} />
        <Route path="image-to-pdf" element={<ImgToPdf />} />
        <Route path="pdf-to-image" element={<PdfToImg />} />
        <Route path="merge-pdf" element={<MergePdf />} />
        <Route path="split-pdf" element={<SplitPDF />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
