import React, { useState, useEffect } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import a2z_logo from "../assets/a2z.png";

const Header = ({
  onCompressClick,
  onAllToolsClick,
  onImgToPdfClick,
  onPdfToImageClick,
  onMergePdfClick,
  onSplitPdfClick,
}) => {
  const navigate = useNavigate();
  const [activeTool, setActiveTool] = useState("flatten");

  useEffect(() => {
    const menuItems = document.querySelectorAll(".navbar-nav li");

    menuItems.forEach((item) => {
      item.addEventListener("click", function () {
        menuItems.forEach((item) => {
          item.classList.remove("active");
        });
        item.classList.add("active");
        setActiveTool(item.getAttribute("data-tool"));
      });
    });

    return () => {
      menuItems.forEach((item) => {
        item.removeEventListener("click", function () {
          menuItems.forEach((item) => {
            item.classList.remove("active");
          });
          item.classList.add("active");
          setActiveTool(item.getAttribute("data-tool"));
        });
      });
    };
  }, []);

  const handleClick = (tool) => {
    setActiveTool(tool);
    switch (tool) {
      case "compress":
        onCompressClick();
        navigate("compress-pdf");
        break;
      case "imgToPdf":
        if (onImgToPdfClick) {
          onImgToPdfClick();
          navigate("image-to-pdf");
        }
        break;
      case "pdfToImage":
        if (onPdfToImageClick) {
          onPdfToImageClick();
          navigate("pdf-to-image");
        }
        break;
      case "mergePdf":
        if (onMergePdfClick) {
          onMergePdfClick();
          navigate("merge-pdf");
        }
        break;
      case "splitPdf":
        if (onSplitPdfClick) {
          onSplitPdfClick();
          navigate("split-pdf");
        }
        break;
      default:
        onAllToolsClick();
        navigate("/");
    }
  };
  const handleMegaMenuClick = () => {
    console.log("clicked menu");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-parent pt-1 pb-0"
      style={{ position: "fixed" }}
    >
      <div className="container  fw-semibold">
        <button
          className="navbar-brand nav-link"
          onClick={() => handleClick("flatten")}
          style={{ color: "#0b8" }}
        >
          <img src={a2z_logo} alt="logo_img" />
        </button>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          style={{ flexGrow: 0 }}
        >
          <ul
            className="navbar-nav mb-2 mb-lg-0 ms-auto"
            onClick={() => handleClick("flatten")}
          >
            <li
              className={`nav-item px-1 p-2 ${
                activeTool === "flatten" ? "active" : ""
              }`}
              data-tool="flatten"
            >
              <button
                className="nav-link fw-bold"
                // onClick={() => handleClick("flatten")}
                style={{ color: activeTool === "flatten" ? "#0b8" : "" }}
              >
                Flatten PDF
              </button>
            </li>
          </ul>
          <ul
            className="navbar-nav mb-2 mb-lg-0 ms-auto"
            onClick={() => handleClick("compress")}
          >
            <li
              className={`nav-item px-1 p-2 ${
                activeTool === "compress" ? "active" : ""
              }`}
              data-tool="compress"
            >
              <button
                className="nav-link"
                // onClick={() => handleClick("compress")}
                style={{ color: activeTool === "compress" ? "#0b8" : "" }}
              >
                Compress PDF
              </button>
            </li>
          </ul>
          <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
            <li
              className={`nav-item px-1 p-2 mega-dropdown ${
                activeTool === "convertPdf" ? "active" : ""
              }`}
              data-tool="convertPdf"
            >
              <button
                className="nav-link"
                style={{ color: activeTool === "convertPdf" ? "#0b8" : "" }}
                onClick={handleMegaMenuClick}
              >
                Convert PDF
              </button>
              <div class="mega-menu">
                <div class="column">
                  <ul>
                    <li>
                      <button
                        className="nav-link"
                        onClick={() => handleClick("imgToPdf")}
                        style={{
                          color: activeTool === "imgToPdf" ? "#0b8" : "",
                        }}
                      >
                        Image to PDF
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="column">
                  <ul>
                    <li>
                      <button
                        className="nav-link"
                        style={{
                          color: activeTool === "pdfToImage" ? "#0b8" : "",
                        }}
                        onClick={() => handleClick("pdfToImage")}
                      >
                        PDF to Image
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>

          <ul
            onClick={() => handleClick("mergePdf")}
            className="navbar-nav mb-2 mb-lg-0 ms-auto"
          >
            <li
              className={`nav-item px-1 p-2 ${
                activeTool === "mergePdf" ? "active" : ""
              }`}
              data-tool="mergePdf"
            >
              <button
                className="nav-link"
                style={{ color: activeTool === "mergePdf" ? "#0b8" : "" }}
              >
                Merge PDF
              </button>
            </li>
          </ul>
          <ul
            className="navbar-nav mb-2 mb-lg-0 ms-auto"
            onClick={() => handleClick("splitPdf")}
          >
            <li
              className={`nav-item px-1 p-2 ${
                activeTool === "splitPdf" ? "active" : ""
              }`}
              data-tool="splitPdf"
            >
              <button
                className="nav-link"
                style={{ color: activeTool === "splitPdf" ? "#0b8" : "" }}
              >
                Split PDF
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
