import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import "../App.css";
const ImageModal = ({
  showModal,
  onClose,
  convertImagesToPdf,
  images,
  marginVal,
  setMarginVal,
  selectedButton,
  setSelectedButton,
}) => {
  const [imageWidth, setImageWidth] = useState();
  const [imageHeight, setImageHeight] = useState();
  const [aspectRatio, setAspectRatio] = useState();

  const handlePortraitClick = () => {
    setSelectedButton("portrait");
  };
  const handleLandscapeClick = () => {
    setSelectedButton("landscape");
  };
  const handleBigMargin = () => {
    setMarginVal("big");
  };
  const handleSmallMargin = () => {
    setMarginVal("small");
  };
  const handleNoMargin = () => {
    setMarginVal("no-margin");
  };
  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const dummyImage = async () => {
    if (images.length > 0) {
      const doc = new jsPDF("p", "mm", "a4");
      for (let index = 0; index < images.length; index++) {
        const image = images[index];
        const dataUrl = await readFileAsDataUrl(image.file);
        const img = new Image();
        // Set up the onload event to calculate dimensions after the image has loaded
        img.onload = function () {
          const aspectRatio = img.width / img.height;

          const maxWidth = selectedButton === "landscape" ? 297 : 210; // Adjust maxWidth for landscape
          const maxHeight = selectedButton === "landscape" ? 280 : 297; // Adjust maxHeight for landscape
          let width = img.width; //original width of the image
          let height = img.height; // original height of the image
          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }
          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
          // Calculate vertical offset to center the image(make space)
          const verticalOffset =
            (doc.internal.pageSize.getHeight() - height) / 2;
          setImageWidth(width);
          setImageHeight(height);
          setAspectRatio(verticalOffset);
        };
        img.src = dataUrl;
      }
    } else {
      alert("Please upload at least one image first.");
    }
  };

  // Modify the formatPageSize function to return an object with width and height properties
  const formatPageSize = (orientation) => {
    const [width, height] = orientation === "landscape" ? [85, 74] : [74, 85]; // A4 page size in mm
    return { width: `${width}mm`, height: `${height}mm` };
  };
  useEffect(() => {
    if (images) {
      dummyImage();
    }
  }, [images.length > 0]);
  return (
    <>
      <div className={`overlay ${showModal ? "show" : ""}`}></div>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content" style={{ height: "85vh" }}>
            <div className="modal-header">
              <h1
                className="modal-title fs-4 ms-auto"
                id="exampleModalLabel"
                style={{ fontWeight: "500" }}
              >
                Image to PDF options
              </h1>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => onClose()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4 border-end">
                    <p className="text-center" style={{ fontWeight: "500" }}>
                      Page Orientation
                    </p>
                    <div className="d-flex justify-content-around mb-5">
                      <button
                        className=""
                        onClick={handlePortraitClick}
                        style={{
                          fontSize: "15px",
                          width: "100px",
                          height: "40px",
                          padding: "0px",
                          border:
                            selectedButton === "portrait"
                              ? "2px solid #00bb87"
                              : "none",
                          borderRadius: "4px",
                          background: "#f5f5fa",
                          color:
                            selectedButton === "portrait" ? "#00bb87" : "#000",
                          fontWeight: "550",
                        }}
                      >
                        Portrait
                      </button>
                      <button
                        className=""
                        onClick={handleLandscapeClick}
                        style={{
                          fontSize: "15px",
                          width: "100px",
                          padding: "0px",
                          border:
                            selectedButton === "landscape"
                              ? "2px solid #00bb87"
                              : "none",
                          borderRadius: "4px",
                          background: "#f5f5fa",
                          color:
                            selectedButton === "landscape" ? "#00bb87" : "#000",
                          fontWeight: "550",
                        }}
                      >
                        Landscape
                      </button>
                    </div>
                    <hr />
                    <p className="text-center" style={{ fontWeight: 500 }}>
                      Margin
                    </p>
                    <div className="d-flex justify-content-between mb-4">
                      <button
                        style={{
                          width: "80px",
                          height: "80px",
                          border:
                            marginVal === "no-margin"
                              ? "2px solid #00bb87"
                              : "none",
                          borderRadius: "4px",
                          background: "#f5f5fa",
                          color: marginVal === "no-margin" ? "#00bb87" : "#000",
                          fontSize: "0.9rem",
                        }}
                        onClick={handleNoMargin}
                      >
                        <i
                          class="fa-solid fa-image pt-1"
                          style={{ fontSize: "1.4rem" }}
                        ></i>
                        <br />
                        No Margin
                      </button>
                      <button
                        style={{
                          width: "80px",
                          height: "80px",
                          border:
                            marginVal === "small"
                              ? "2px solid #00bb87"
                              : "none",
                          borderRadius: "4px",
                          background: "#f5f5fa",
                          color: marginVal === "small" ? "#00bb87" : "#000",
                          fontSize: "0.9rem",
                        }}
                        onClick={handleSmallMargin}
                      >
                        <i
                          class="fa-solid fa-image pt-1"
                          style={{ fontSize: "1.4rem" }}
                        ></i>
                        <br />
                        Small
                      </button>
                      <button
                        style={{
                          width: "80px",
                          height: "80px",
                          border:
                            marginVal === "big" ? "2px solid #00bb87" : "none",
                          borderRadius: "4px",
                          background: "#f5f5fa",
                          color: marginVal === "big" ? "#00bb87" : "#000",
                          fontSize: "0.9rem",
                        }}
                        onClick={handleBigMargin}
                      >
                        <i
                          class="fa-solid fa-image pt-1"
                          style={{ fontSize: "1.4rem" }}
                        ></i>
                        <br />
                        Big
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-8 py-3 px-5">
                    <div
                      id="carouselExampleControls"
                      className="carousel slide rounded"
                      // data-bs-ride="carousel"
                      style={{
                        height: "390px",
                        boxShadow:
                          "0 1px 2px rgba(0,0,0,0.07), 0 2px 4px rgba(0,0,0,0.07), 0 4px 8px rgba(0,0,0,0.07), 0 8px 16px rgba(0,0,0,0.07), 0 16px 32px rgba(0,0,0,0.07), 0 32px 64px rgba(0,0,0,0.07)",
                      }}
                    >
                      <div className="carousel-inner">
                        {images.map((image, index) => (
                          <div
                            key={index}
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                            style={{ height: "390px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "2rem",
                              }}
                            >
                              <div
                                style={{
                                  ...formatPageSize(selectedButton),
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow:
                                    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                                  paddingTop:
                                    selectedButton === "landscape"
                                      ? ""
                                      : `${aspectRatio}px`,
                                  paddingBottom:
                                    selectedButton === "landscape"
                                      ? ""
                                      : `${aspectRatio}px`,
                                }}
                                className=""
                              >
                                <div
                                  style={{
                                    width: `${
                                      selectedButton === "landscape" ? 320 : 280
                                    }px`,
                                    height: `${
                                      selectedButton === "landscape" ? 250 : 160
                                    }px`,
                                    overflow: "hidden",
                                    objectFit: "cover",
                                  }}
                                >
                                  <img
                                    src={URL.createObjectURL(image.file)}
                                    alt={`Image ${index}`}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      paddingLeft: `${
                                        marginVal === "small"
                                          ? 8
                                          : marginVal === "big"
                                          ? 16
                                          : 0
                                      }px`,
                                      paddingRight: `${
                                        marginVal === "small"
                                          ? 8
                                          : marginVal === "big"
                                          ? 16
                                          : 0
                                      }px`,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                          style={{ backgroundColor: "#059971" }}
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="image-to-pdf-btn"
                onClick={() => convertImagesToPdf()}
              >
                Convert To PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
