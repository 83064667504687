import React, { useRef, useState, useEffect } from "react";
import "../App.css";
import Footer from "./Footer";
import { PDFDocument } from "pdf-lib";
import JSZip from "jszip";
import pdfIcon1 from "../assets/pdfIcon (1).png";
const CompressPdf = () => {
  const [file, setFile] = useState([]);
  const [compressedPdfSize, setCompressedPdfSize] = useState([]);
  const [onePdfCompressSize, setOnePdfCompressSize] = useState();
  const [totalCompressedSize, setTotalCompressedSize] = useState(0);
  const [compressedFiles, setCompressedFiles] = useState([]);
  const [trackAllPdf, setTrackAllPdf] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pdfFileNames, setPdfFileNames] = useState("");
  const [pdfFileSize, setPdfFileSize] = useState(0);
  const [pageRendering, setPageRendering] = React.useState("");
  const [dropzoneBackground, setDropzoneBackground] = useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [pdfRendering, setPdfRendering] = React.useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [pdfImages, setPdfImages] = useState([]);
  const [canvases, setCanvases] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadedPdfCount, setUploadedPdfCount] = useState(0);
  const [isRefersh, setIsRefersh] = useState(false);
  const [allclear, setAllClear] = useState(false);
  const handleFileInputChange = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const selectedFiles = e.target?.files;

    if (selectedFiles && selectedFiles.length > 0) {
      try {
        setPdfRendering(true);
        const newFiles = [];
        const pdfDocs = await Promise.all(
          Array.from(selectedFiles).map(async (file) => {
            const uri = URL.createObjectURL(file);
            return await window.pdfjsLib.getDocument({ url: uri });
          })
        );
        const newCanvases = Array.from(pdfDocs).map(() =>
          document.createElement("canvas")
        );
        // Concatenate new canvases with existing ones
        setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

        // Concatenate new PDF images with existing ones
        setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);
        // Loop through each selected file
        for (const selectedFile of selectedFiles) {
          setPdfRendering(false);

          // Add each file to the newFiles array
          newFiles.push({
            file: selectedFile,
            name: selectedFile.name,
            size: selectedFile.size,
          });
        }

        // Initialize compressedFiles state with compression-related information
        setCompressedFiles((prevFiles) => [
          ...prevFiles,
          ...newFiles.map(() => ({
            compressionRate: null,
            compressedBlob: null,
          })),
        ]);

        // Update the files state with the newFiles array
        setFile((prevFiles) => [...prevFiles, ...newFiles]);
        setUploadedPdfCount((prevCount) => prevCount + newFiles.length);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    setDropzoneBackground(false);

    const droppedFiles = e.dataTransfer.files;

    if (droppedFiles && droppedFiles.length > 0) {
      try {
        setPdfRendering(true);
        const newFiles = [];
        const pdfDocs = await Promise.all(
          Array.from(droppedFiles).map(async (file) => {
            const uri = URL.createObjectURL(file);
            return await window.pdfjsLib.getDocument({ url: uri });
          })
        );
        const newCanvases = Array.from(pdfDocs).map(() =>
          document.createElement("canvas")
        );
        // Concatenate new canvases with existing ones
        setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

        // Concatenate new PDF images with existing ones
        setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);
        // Loop through each selected file
        for (const selectedFile of droppedFiles) {
          setPdfRendering(false);

          // Add each file to the newFiles array
          newFiles.push({
            file: selectedFile,
            name: selectedFile.name,
            size: selectedFile.size,
          });
        }

        // Initialize compressedFiles state with compression-related information
        setCompressedFiles((prevFiles) => [
          ...prevFiles,
          ...newFiles.map(() => ({
            compressionRate: null,
            compressedBlob: null,
          })),
        ]);

        // Update the files state with the newFiles array
        setFile((prevFiles) => [...prevFiles, ...newFiles]);
        setUploadedPdfCount((prevCount) => prevCount + newFiles.length);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  useEffect(() => {
    if (uploadedPdfCount > 1) {
      setIsDisabled(true);
      setPdfData(null);
    }
  }, [file]);
  // render page function
  async function renderPage(index) {
    console.log("index:", index);
    setPageRendering(true);
    const selectedPdf = pdfImages[index];

    var page = await selectedPdf?.getPage(currentPage);

    var viewport = page?.getViewport({ scale: 1 });
    const scale = Math.min(200 / viewport.width, 215 / viewport.height);
    console.log("scale:", scale);
    var render_context = {
      canvasContext: document
        .querySelector(`.pdf-canvas-${index}`)
        ?.getContext("2d"),
      viewport: page.getViewport({ scale }),
    };

    setWidth(viewport.width * scale);
    setHeight(viewport.height * scale);
    await page.render(render_context);
    setPageRendering(false);
  }
  // render image in img tag use effect
  useEffect(() => {
    for (let i = 0; i < pdfImages.length; i++) {
      renderPage(i);
    }
    // eslint-disable-next-line
  }, [pdfImages, currentPage]);

  const compressFile = async (pdfBytes) => {
    try {
      const pdfDoc = await PDFDocument.load(pdfBytes);

      // Save the compressed PDF bytes
      const compressedPdfBytes = await pdfDoc.save();

      return compressedPdfBytes;
    } catch (error) {
      console.error("Error compressing file", error);
      throw error;
    }
  };
  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("pdf", file[0].file);
    const pdfFileData = file[0].file;
    try {
      // Load the PDF document
      const fileBytes = await pdfFileData.arrayBuffer();

      // Perform compression using the separate compressFile function
      const compressedPdfBytes = await compressFile(fileBytes);

      const compressedPdfBlob = new Blob([compressedPdfBytes], {
        type: "application/pdf",
      });

      // Set the compressed PDF data in state or perform other actions as needed
      setPdfData({ compressedBlob: compressedPdfBlob });
      setIsDisabled(true);

      // Set the compressed PDF size in state
      // setCompressedPdfSize(compressedPdfBytes.length);
      setOnePdfCompressSize(compressedPdfBytes.length);

      console.log("Compressed PDF Size:", compressedPdfBytes.length, "bytes");
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleDownload = () => {
    if (pdfData && pdfData.compressedBlob) {
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(pdfData.compressedBlob);
      downloadLink.download = "compressed_file.pdf";
      // Append the link to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();
      // Remove the link from the body
      document.body.removeChild(downloadLink);
      setPdfData(null);
      setIsRefersh(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDropzoneBackground(true);
  };

  const handleDeletePdf = (index) => {
    // Remove the specified PDF from the files state
    const newPdfImages = [...pdfImages];
    newPdfImages.splice(index, 1);
    setPdfImages(newPdfImages);

    setFile((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
    setCompressedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });

    // Clear the file input value
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
    if (file.length === 1) {
      setIsRefersh(false);
    }
  };
  const calculateCompressionRate = (originalSize, compressedSize) => {
    if (originalSize === 0 || compressedSize >= originalSize) {
      return 0; // Avoid division by zero or invalid values
    }

    const reduction = ((originalSize - compressedSize) / originalSize) * 100;
    return reduction.toFixed(2); // Returns the compression rate with two decimal places
  };

  const handleCompress = async (index) => {
    const selectedFile = file[index];

    try {
      const fileBytes = await selectedFile.file.arrayBuffer();

      const compressedPdfBytes = await compressFile(fileBytes);

      // Ensure that both fileBytes and compressedPdfBytes have valid byteLength properties
      if (
        fileBytes &&
        fileBytes.byteLength !== undefined &&
        compressedPdfBytes &&
        compressedPdfBytes.length !== undefined
      ) {
        // Calculate the compression rate
        const compressionRate = calculateCompressionRate(
          fileBytes.byteLength,
          compressedPdfBytes.length
        );
        // setCompressedPdfSize(compressedPdfBytes.length);
        // Set the compressed size for the corresponding index
        setCompressedPdfSize((prevSizes) => {
          const newSizes = [...prevSizes];
          newSizes[index] = compressedPdfBytes.byteLength;
          return newSizes;
        });
        // Update the compressedFiles state with compression rate and compressed blob
        setCompressedFiles((prevFiles) =>
          prevFiles.map((prevFile, i) =>
            i === index
              ? {
                  ...prevFile,
                  compressionRate: compressionRate,
                  compressedBlob: new Blob([compressedPdfBytes], {
                    type: "application/pdf",
                  }),
                }
              : prevFile
          )
        );
      } else {
        console.error("Error: Invalid fileBytes or compressedPdfBytes");
      }
    } catch (error) {
      console.error("Error compressing file", error);
    }
  };

  const handleCompressAll = async () => {
    try {
      for (let i = 0; i < file.length; i++) {
        await handleCompress(i);
      }

      setTrackAllPdf(true);
    } catch (error) {
      console.error("Error compressing all PDFs", error);
    }
  };

  const handleDownloadAll = () => {
    try {
      const zip = new JSZip();

      // Add each compressed PDF to the zip file
      compressedFiles.forEach((compressedFile, index) => {
        const fileName = `compressed_pdf_${index + 1}.pdf`;
        zip.file(fileName, compressedFile.compressedBlob);
      });

      // Generate the zip file
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        const zipFileName = "compressed_pdfs.zip";
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = zipFileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
      setTrackAllPdf(false);

      setAllClear(true);
    } catch (error) {
      console.error("Error downloading all compressed PDFs", error);
    }
  };

  useEffect(() => {
    if (file === null) {
      setIsDisabled(false);
    }
  }, [file]);
  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };
  const clearAll = () => {
    // Remove the specified PDF from both states
    setFile([]);
    setPdfImages([]);
    setCompressedPdfSize([]);
    setCompressedFiles([]);
    setPdfFileNames("");
    setPdfFileSize(0);
    setPdfImages("");
    setPdfData(null);
    setAllClear(false);
    setIsRefersh(false);
    // Clear the file input value
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  return (
    <>
      <div
        className="main-pdf-body pt-2 pb-4  mb-5 px-sm-0"
        style={{ overflow: "hidden" }}
      >
        <div
          className="container"
          style={{
            border: "2px dotted #00bb87",
            padding: "0px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            className="border text-center d-flex align-items-center justify-content-center drop-area"
            style={{
              height: "200px",
              cursor: "pointer",
              background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()}
          >
            <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
              Select or drop your files here <br />
              <i
                className="fa-solid fa-cloud-arrow-up"
                style={{ fontSize: "5rem", color: "#54bc8b" }}
              ></i>
            </p>
          </div>
          <input
            type="file"
            id="fileInput"
            accept="application/pdf"
            multiple
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
        <>
          {file.length === 1 ? (
            <div className="container">
              {pdfImages.map((canvas, index) => (
                <div
                  key={index}
                  className="pdf-card text-center"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{ border: "" }}
                >
                  <canvas
                    className={`pdf-canvas-${index}`}
                    width={width}
                    height={height}
                    style={{
                      boxShadow:
                        "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
                      marginTop: "0.5rem",
                    }}
                  ></canvas>
                  {hoveredIndex === index && (
                    <button
                      className="canvas-delete-btn mt-2"
                      onClick={() => handleDeletePdf(index)}
                    >
                      <i
                        class="fa-solid fa-xmark"
                        style={{
                          margin: "0px",
                          padding: "0.33rem 0 0 0",
                          width: "25px",
                          height: "25px",
                        }}
                      ></i>
                    </button>
                  )}
                  <div class="name-box">
                    <p class="name">
                      {file[index]?.name} <br />
                      {formatFileSize(file[index]?.size)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div class="container p-0 mt-3">
              <div class="row gx-3">
                {file.map((file, index) => (
                  <div key={index} className="col-md-6">
                    <div
                      key={index}
                      className="compress-card my-2 d-flex align-items-center px-3"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      style={{
                        boxShadow: "0 2px 5px 2px rgba(0,0,0,0.09)",
                        borderRadius: "7px",
                      }}
                    >
                      {hoveredIndex === index && (
                        <button
                          className="canvas-delete-btn mt-2"
                          onClick={() => handleDeletePdf(index)}
                        >
                          <i
                            className="fa-solid fa-xmark"
                            style={{
                              margin: "0px",
                              padding: "0.33rem 0 0 0",
                              width: "25px",
                              height: "25px",
                            }}
                          ></i>
                        </button>
                      )}
                      <div className="fw-bold my-2 px-0 mx-0">
                        <img
                          src={pdfIcon1}
                          alt="pdf-img"
                          className="pdf-image p-0"
                        />
                      </div>
                      <div>
                        <p className="name fw-semibold">
                          {file.name} <br />
                          <span className="name ">
                            {formatFileSize(file.size)}
                            {/* {(file.size / 1000).toFixed(2)} KB */}
                          </span>
                        </p>
                      </div>
                      <div className="ms-auto me-3">
                        {!(compressedFiles[index].compressedBlob !== null) && (
                          <button
                            className="pdf-btn"
                            onClick={() => handleCompress(index)}
                          >
                            Compress
                          </button>
                        )}
                      </div>
                      {compressedFiles[index].compressedBlob && (
                        <>
                          <div
                            className="text-end me-2"
                            style={{ width: "150px", wordBreak: "break-word" }}
                          >
                            <p className=" fw-bold name">
                              {compressedFiles[index].compressionRate}%
                              <br />
                              <span className="name fw-semibold">
                                {(compressedPdfSize[index] / 1000).toFixed(2)}{" "}
                                KB
                              </span>
                            </p>
                          </div>
                          <div style={{ textAlign: "center" }}></div>
                          <div>
                            <a
                              href={window.URL.createObjectURL(
                                compressedFiles[index].compressedBlob
                              )}
                              download={`${file.name}_compressed.pdf`}
                            >
                              <button className="compress-pdf-down">
                                <i
                                  class="fa-solid fa-download"
                                  style={{ fontSize: "1rem" }}
                                ></i>
                              </button>
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      </div>
      {file.length === 1 ? (
        <Footer
          isCompressPdfRoute={true}
          isDisabled={isDisabled}
          onUpload={(e) => uploadFile(e)}
          onDownload={handleDownload}
          pdfData={pdfData}
          compressedPdfSize={onePdfCompressSize}
          refersh={clearAll}
          isRefersh={isRefersh}
        />
      ) : (
        <>
          {file.length > 1 ? (
            <>
              {" "}
              <Footer
                isCompressPdfRoute={true}
                handleCompressAll={handleCompressAll}
                handleDownloadAll={handleDownloadAll}
                trackAllPdf={trackAllPdf}
                totalCompressedSize={totalCompressedSize}
                refersh={clearAll}
                allclear={allclear}
              />{" "}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default CompressPdf;
