import React, { useState, useRef, useEffect } from "react";
import Footer from "./Footer";
import { PDFDocument } from "pdf-lib";
import { DndProvider } from "react-dnd";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import PdfCard from "./PdfCard";
import { render } from "@testing-library/react";
const MAX_COUNT = 20;

const MergePdf = () => {
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pdfFileNames, setPdfFileNames] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [pdfRendering, setPdfRendering] = useState("");
  const [dropzoneBackground, setDropzoneBackground] = useState(false);
  const [pageRendering, setPageRendering] = useState("");
  const [currentPageImg, setCurrentPageImg] = useState(1);
  const [mergeIsDisabled, setMergeIsDisabled] = useState(false);
  const [mergedPdf, setMergedPdf] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [pdfImages, setPdfImages] = useState([]);
  const [canvases, setCanvases] = useState([]);

  const handleFileInputChange = async (e) => {
    e.preventDefault();
    // setButtonDisabled(true);

    const files = e.target.files;

    // Check file count
    if (files.length + selectedFile.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }

    // Check duplicate files
    const duplicateFiles = Array.from(files).filter((file) =>
      selectedFile.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }

    const newFiles = Array.from(files);

    setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);

    const newPdfFileNames = Array.from(files).map((file, index) => ({
      name: file.name,
      size: file.size,
      order: pdfFileNames.length + index + 1, // Set order based on current number of PDFs
    }));
    setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);
    // ... existing code

    try {
      setPdfRendering(true);
      const pdfDocs = await Promise.all(
        Array.from(files).map(async (file) => {
          const uri = URL.createObjectURL(file);
          return await window.pdfjsLib.getDocument({ url: uri });
        })
      );
      const newCanvases = Array.from(pdfDocs).map(() =>
        document.createElement("canvas")
      );
      // Concatenate new canvases with existing ones
      setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

      // Concatenate new PDF images with existing ones
      setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);

      setPdfRendering(false);
    } catch (error) {
      alert(error.message);
    }
  };
  const handleDrop = async (e) => {
    e.preventDefault();

    setDropzoneBackground(false);
    const droppedFiles = e.dataTransfer.files;

    // Check file count
    if (droppedFiles.length + selectedFile.length > MAX_COUNT) {
      alert(`You can only upload a maximum of ${MAX_COUNT} files.`);
      return;
    }

    // Check duplicate files
    const duplicateFiles = Array.from(droppedFiles).filter((file) =>
      selectedFile.some((prevFile) => prevFile.name === file.name)
    );
    if (duplicateFiles.length > 0) {
      alert(`Duplicate files are not allowed.`);
      return;
    }

    if (droppedFiles.length > 0) {
      // Concatenate new files with existing ones
      const newFiles = Array.from(droppedFiles);
      setSelectedFile((prevFiles) => [...prevFiles, ...newFiles]);

      const newPdfFileNames = Array.from(droppedFiles).map((file, index) => ({
        name: file.name,
        size: file.size,
        order: pdfFileNames.length + index + 1, // Set order based on current number of PDFs
      }));
      setPdfFileNames((prevNames) => [...prevNames, ...newPdfFileNames]);

      try {
        setPdfRendering(true);
        const pdfDocs = await Promise.all(
          newFiles.map(async (file) => {
            const uri = URL.createObjectURL(file);
            return await window.pdfjsLib.getDocument({ url: uri });
          })
        );
        const newCanvases = Array.from(pdfDocs).map(() =>
          document.createElement("canvas")
        );
        // Concatenate new canvases with existing ones
        setCanvases((prevCanvases) => [...prevCanvases, ...newCanvases]);

        // Concatenate new PDF images with existing ones
        setPdfImages((prevImages) => [...prevImages, ...pdfDocs]);

        setPdfRendering(false);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const handleReorder = (startIndex, endIndex) => {
    const reorderedFiles = Array.from(selectedFile);
    const reorderedPdfImages = Array.from(pdfImages);
    const recordFileName = Array.from(pdfFileNames);
    const [moveFileName] = recordFileName.splice(startIndex, 1);
    recordFileName.splice(endIndex, 0, moveFileName);
    setPdfFileNames(recordFileName);
    const [movedFile] = reorderedFiles.splice(startIndex, 1);
    reorderedFiles.splice(endIndex, 0, movedFile);

    const [movedPdf] = reorderedPdfImages.splice(startIndex, 1);
    reorderedPdfImages.splice(endIndex, 0, movedPdf);

    setSelectedFile(reorderedFiles);
    setPdfImages(reorderedPdfImages);
  };

  // render page function
  async function renderPage(index) {
    setPageRendering(true);
    const selectedPdf = pdfImages[index];

    var page = await selectedPdf?.getPage(currentPageImg);

    var viewport = page?.getViewport({ scale: 1 });
    const scale = Math.min(200 / viewport.width, 215 / viewport.height);
    console.log("scale:", scale);
    var render_context = {
      canvasContext: document
        .querySelector(`.pdf-canvas-${index}`)
        ?.getContext("2d"),
      viewport: page.getViewport({ scale }),
    };
    await page.render(render_context);
    setPageRendering(false);
  }
  // render image in img tag use effect
  useEffect(() => {
    for (let i = 0; i < pdfImages.length; i++) {
      renderPage(i);
    }
    // eslint-disable-next-line
  }, [pdfImages, currentPage]);
  const handleDragOver = (e) => {
    e.preventDefault();
    setDropzoneBackground(true);
  };

  const handleDeletePdf = (index) => {
    const newPdfFileNames = [...pdfFileNames];
    const newSelectedFile = [...selectedFile];
    const newPdfImages = [...pdfImages];
    const newCanvases = [...canvases];

    newPdfFileNames.splice(index, 1);
    newSelectedFile.splice(index, 1);
    newPdfImages.splice(index, 1);
    newCanvases.splice(index, 1);

    setPdfFileNames(newPdfFileNames);
    setSelectedFile(newSelectedFile);
    setPdfImages(newPdfImages);
    setCanvases(newCanvases);
  };

  const formatFileSize = (size) => {
    const sizeInKB = size / 1024;

    return sizeInKB < 1000
      ? `${sizeInKB.toFixed(2)} KB`
      : `${(sizeInKB / 1024).toFixed(2)} MB`;
  };

  const mergePDFs = async () => {
    try {
      const mergedDoc = await PDFDocument.create();

      // Iterate through selectedFile instead of pdfImages
      for (let i = 0; i < selectedFile.length; i++) {
        const fileBytes = await selectedFile[i].arrayBuffer();
        const pdfDoc = await PDFDocument.load(fileBytes);

        const copiedPages = await mergedDoc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => mergedDoc.addPage(page));
      }

      const mergedPdfBytes = await mergedDoc.save();
      setMergedPdf(new Blob([mergedPdfBytes], { type: "application/pdf" }));
      setMergeIsDisabled(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const downloadMergedPDF = () => {
    if (mergedPdf) {
      const url = URL.createObjectURL(mergedPdf);
      const a = document.createElement("a");
      a.href = url;
      a.download = "a2z-mergedPdf.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setMergeIsDisabled(false);
    }
  };

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className="main-pdf-body pt-2 pb-2">
        <div
          className="container"
          style={{
            border: "2px dotted #00bb87",
            padding: "0px",
            marginTop: "20px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <div
            className="border text-center d-flex align-items-center justify-content-center drop-area"
            style={{
              height: "200px",
              cursor: "pointer",
              background: `${dropzoneBackground ? "#bcddd4" : "#d5eee7"}`,
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={() => fileInputRef.current.click()} // Add this line
          >
            <p className="h5 fw-bold pt-3" style={{ color: "#54bc8b" }}>
              Select or drop your files here <br />
              <i
                className="fa-solid fa-cloud-arrow-up"
                style={{ fontSize: "5rem", color: "#54bc8b" }}
              ></i>
            </p>
          </div>
          <input
            type="file"
            id="fileInput"
            accept="application/pdf"
            multiple
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <div
        className={`container  mt-3 mb-5  mt-0 ${
          !isMobile ? "pdf-container" : ""
        }`}
      >
        {pdfImages.map((canvas, index) => (
          <PdfCard
            key={pdfFileNames[index].name}
            index={index}
            pdf={canvas}
            onDelete={handleDeletePdf}
            onReorder={handleReorder}
            hoveredIndex={hoveredIndex}
            setHoveredIndex={setHoveredIndex}
            width={width}
            height={height}
            pdfFileNames={pdfFileNames}
            formatFileSize={formatFileSize}
            isMobile={isMobile}
          />
        ))}
      </div>
      <Footer
        mergeSelectedFile={selectedFile.length > 1}
        mergePDF={mergePDFs}
        downloadMergedPDF={downloadMergedPDF}
        mergeIsDisabled={mergeIsDisabled}
      />
    </DndProvider>
  );
};
export default MergePdf;
